<template>
  <BaseMapForm v-if="meta" :price="values.price">
    <div class="form-group">
      <label for="format">道路範圍</label>
      <select v-bind="index" name="index" class="form-control form-control-lg">
        <option v-for="road in roads" :key="road.name" :value="road.name">
          {{ road.name }}
        </option>
      </select>
      <div class="error-feedback">{{ errors.index }}</div>
    </div>
    <MapTextInput name="format" displayText="資料格式" />
  </BaseMapForm>
</template>

<script setup lang="ts">
import { MetaMap, QueryCreateDto, QueryMap, QueryUpdateDto } from "@/api/api";
import { injectDefault } from "@/script/common";
import { useForm } from "vee-validate";
import { Ref, inject, provide, ref } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";
import MapTextInput from "./MapTextInput.vue";

const roads = [
  { name: "國道" },
  { name: "快速道路" },
  { name: "臺中市" },
  { name: "高雄市" },
];

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required(`請輸入${meta?.value.indexName}`),
  format: yup.string().required("請輸入資料格式"),
});
const { values, errors, isSubmitting, handleSubmit, defineInputBinds } =
  useForm({
    validationSchema: schema,
    initialValues: {
      type: meta?.value.mapName,
      mid: meta?.value.id,
      price: meta?.value.price,
      index: selectQuery?.value?.index || "",
      format: selectQuery?.value?.format || "",
    },
  });
const index = defineInputBinds("index");

const onSubmit = handleSubmit((values) => {
  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, values);
    return;
  }
  addQuery(values);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("onSubmit", onSubmit);
</script>
