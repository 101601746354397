<template>
  <BaseMapForm v-if="meta" :price="values.price">
    <MapTextInput name="index" :displayText="meta.indexName" />
    <MapTextInput name="coord" displayText="坐標系統" />
    <MapTextInput name="format" displayText="資料格式" :disabled="true" />
    <MapTextInput name="year" displayText="繪製年份" v-if="meta.hasYear" />
  </BaseMapForm>
</template>

<script setup lang="ts">
import { MetaMap, QueryCreateDto, QueryMap, QueryUpdateDto } from "@/api/api";
import { injectDefault } from "@/script/common";
import { useForm } from "vee-validate";
import { Ref, inject, provide, ref } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";
import MapTextInput from "./MapTextInput.vue";

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required("請輸入索引號"),
  coord: yup.string(),
  format: yup.string().required("請輸入資料格式"),
  year: yup.string().when("needYear", {
    is: true,
    then: (schema) => schema.required("請輸入繪製年份"),
  }),
  price: yup.number(),
  needYear: yup.boolean(),
});

const { values, isSubmitting, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    type: meta?.value.mapName,
    mid: meta?.value.id,
    scale: meta?.value.fixScale,
    format: meta?.value.fixFormat,
    price: meta?.value.price,
    needYear: meta?.value.hasYear,
    index: selectQuery?.value?.index || "",
    coord: selectQuery?.value?.coord || "",
    year: selectQuery?.value?.year || "",
  },
});

const onSubmit = handleSubmit((values) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { needYear, ...rest } = values;
  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, rest);
    return;
  }
  addQuery(rest);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("onSubmit", onSubmit);
</script>
