<template>
  <BaseMapForm v-if="meta" :price="values.price">
    <div class="form-group">
      <label for="format">API種類</label>
      <select v-bind="index" name="index" class="form-control form-control-lg">
        <option v-for="api in apis" :key="api.name" :value="api.apicode">
          【{{ api.apitype }}】{{ api.name }}
        </option>
      </select>
      <div class="error-feedback">{{ errors.index }}</div>
    </div>
  </BaseMapForm>
</template>

<script setup lang="ts">
import {
  MetaApi,
  MetaMap,
  QueryCreateDto,
  QueryMap,
  QueryUpdateDto,
} from "@/api/api";
import { apiClient } from "@/script/api-client";
import { injectDefault } from "@/script/common";
import { useForm } from "vee-validate";
import { Ref, inject, provide, ref, watch } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

// Load Data
const apis = ref<MetaApi[]>([]);
apiClient.getMetaApis().then((res) => {
  apis.value = res;
});

// Form Section
const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required("請輸入索引號"),
});
const { values, errors, isSubmitting, handleSubmit, defineInputBinds } =
  useForm({
    validationSchema: schema,
    initialValues: {
      type: meta?.value.mapName,
      mid: meta?.value.id,
      price: meta?.value.price,
      index: selectQuery?.value?.index || "",
    },
  });
const index = defineInputBinds("index");

const indexExtra = ref("");
watch(values, async (newValues) => {
  if (newValues.index === "") return;
  indexExtra.value =
    apis.value.find((api) => api.apicode === newValues.index)?.name || "";
});

const onSubmit = handleSubmit((values) => {
  const payload = { ...values, indexExtra: indexExtra.value };
  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, payload);
    return;
  }
  addQuery(payload);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("onSubmit", onSubmit);
</script>
