import {
  Api,
  GetPriceTypesParamsTypeEnum,
  GetSchoolsParamsTypeEnum,
} from "@/api/api";
import { store } from "@/store";
function fetch_token() {
  return store.getters.user.token;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function unauthorizedHandler(error: any) {
  if (error.error?.statusCode == 401) {
    store.dispatch.user.logOut();
    return "";
  }
  throw error;
}

export function fetch_token_header() {
  return {
    headers: {
      Authorization: `Bearer ${fetch_token()}`,
    },
  };
}

export class ApiClient extends Api<unknown> {
  constructor() {
    super({
      baseApiParams: fetch_token_header(),
    });
  }
  gid() {
    return store.state.user.group?.gid || 0;
  }
  async getMeta() {
    return this.metadata.getMeta().then((res) => res.data);
  }
  async getGroup() {
    return this.group
      .get(fetch_token_header())
      .then((res) => res.data)
      .then((group) => {
        store.dispatch.user.setGroup(group);
        return group;
      });
  }
  async getGroupsDetail() {
    return this.admin.getGroups().then((res) => res.data);
  }
  async getStudents(gid: number) {
    return this.student.get(gid).then((res) => res.data);
  }
  async getTeachers(gid: number) {
    return this.teacher.get(gid).then((res) => res.data);
  }
  async getProject(gid: number) {
    return this.project.get(gid).then((res) => res.data);
  }
  async getQueries(gid: number) {
    return this.mapQuery.get(gid).then((res) => res.data);
  }
  async getMetaSchools(category: GetSchoolsParamsTypeEnum) {
    return this.metadata.getSchools({ type: category }).then((res) => res.data);
  }
  async getMetaCities() {
    return this.metadata.getCities().then((res) => res.data);
  }
  async getMetaMaps() {
    return this.metadata.getMapTypes().then((res) => res.data);
  }
  async getMetaApis() {
    return this.metadata.getApiTypes().then((res) => res.data);
  }
  async getMetaPrices(category: GetPriceTypesParamsTypeEnum) {
    return this.metadata
      .getPriceTypes({ type: category })
      .then((res) => res.data);
  }
}

export const apiClient = new ApiClient();
