/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GroupResponseDto {
  /**
   * 組別編號
   * @example 1
   */
  gid: number;
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email: string;
  /**
   * 學校類別
   * @example "highschool"
   */
  category: GroupResponseDtoCategoryEnum;
  /**
   * 隊伍狀態
   * @example "created"
   */
  status: GroupResponseDtoStatusEnum;
  /**
   * 隊伍身分
   * @example "user"
   */
  role: GroupResponseDtoRoleEnum;
}

export interface GroupStatusRequestDto {
  /**
   * 隊伍狀態
   * @example "created"
   */
  status: GroupStatusRequestDtoStatusEnum;
}

export interface MetaResponseDto {
  /**
   * 當前屆數
   * @example 6
   */
  currentTerm: number;
  /**
   * 單組最大學生數
   * @example 3
   */
  max_student: number;
  /**
   * 單組最大金額
   * @example 30000
   */
  max_price: number;
  /**
   * 報名截止日期
   * @example "2024-01-12T16:00:00.000Z"
   */
  register_deadline: string;
  /**
   * 報告截止日期
   * @example "2024-03-15T16:00:00.000Z"
   */
  report_deadline: string;
}

export interface MetaSchool {
  /**
   * record id
   * @example "1"
   */
  id: number;
  /**
   * 學校代碼
   * @example "10301"
   */
  code: string;
  /**
   * 縣市
   * @example "臺北市"
   */
  city: string;
  /**
   * 學校名稱
   * @example "國立華僑高級中等學校"
   */
  name: string;
  /**
   * 學校類別
   * @example "highschool"
   */
  category: MetaSchoolCategoryEnum;
}

export interface MetaCity {
  /**
   * 縣市
   * @example "臺北市"
   */
  city: string;
}

export interface MetaMap {
  /**
   * record id
   * @example "1"
   */
  id: number;
  /**
   * 地圖名稱
   * @example "數值地形模型"
   */
  mapName: string;
  /**
   * 是否有索引
   * @example true
   */
  hasIndex: boolean;
  /**
   * 索引名稱
   * @example "圖幅號"
   */
  indexName: string;
  /**
   * 是否有座標
   * @example true
   */
  hasCoord: boolean;
  /**
   * 是否需要座標
   * @example true
   */
  needCoord: boolean;
  /**
   * 是否有比例尺
   * @example true
   */
  hasScale: boolean;
  /**
   * 固定比例尺
   * @example "5000"
   */
  fixScale: string;
  /**
   * 是否有格式
   * @example true
   */
  hasFormat: boolean;
  /**
   * 固定格式
   * @example "SHP"
   */
  fixFormat: string;
  /**
   * 是否有縣市
   * @example true
   */
  hasCountry: boolean;
  /**
   * 是否有年份
   * @example true
   */
  hasYear: boolean;
  /**
   * 單位名稱
   * @example "幅"
   */
  unitName: string;
  /**
   * 價錢
   * @example "1200"
   */
  price: number;
  /**
   * 地圖分類
   * @example "singlemap"
   */
  category: MetaMapCategoryEnum;
  /**
   * 臺灣地圖分類
   * @example "traffic"
   */
  taiwancategory: MetaMapTaiwancategoryEnum;
}

export interface MetaApi {
  /**
   * record id
   * @example "1"
   */
  id: number;
  /**
   * API 名稱
   * @example "指定地號查詢位置"
   */
  name: string;
  /**
   * API 種類
   * @example "地籍API"
   */
  apitype: string;
  /**
   * API 代碼
   * @example "CAD_001"
   */
  apicode: string;
  /**
   * API 編碼
   * @example "CadasMapPosition"
   */
  encode: string;
}

export interface MetaTaiwanMapPrice {
  /**
   * record id
   * @example "1"
   */
  id: number;
  /**
   * 縣市名稱
   * @example "臺北市"
   */
  county: string;
  /**
   * 地圖種類
   * @example "traffic"
   */
  category: MetaTaiwanMapPriceCategoryEnum;
  /**
   * 價格
   * @example 100
   */
  price: number;
}

export interface Attachment {
  /**
   * 檔案編號
   * @example 1
   */
  aid: number;
  /**
   * 檔案類型
   * @example "affidavit"
   */
  type: AttachmentTypeEnum;
  /**
   * 檔案名稱
   * @example "CO006001_affidavit.pdf"
   */
  filename: string;
  /**
   * 上傳時間
   * @example 1700681412902
   */
  upload_at: number;
}

export interface Project {
  /**
   * 專題編號
   * @example "1"
   */
  pid: number;
  /**
   * 參賽編號
   * @example "HS0050005"
   */
  pcode: string;
  /**
   * 主題名稱
   * @example "三維路徑規劃"
   */
  title: string;
  /** 摘要 */
  abstract: string;
  /** Youtube 連結 */
  ytlink: string;
  /** 檔案 */
  attachments: Attachment[] | null;
}

export interface ProjectCreateDto {
  /**
   * 主題名稱
   * @example "三維路徑規劃"
   */
  title: string;
  /** 摘要 */
  abstract: string;
}

export interface ProjectUpdateDto {
  /**
   * 主題名稱
   * @example "三維路徑規劃"
   */
  title?: string;
  /** 摘要 */
  abstract?: string;
  /** Youtube 連結 */
  ytlink?: string;
}

export interface QueryMap {
  /**
   * 申請編號
   * @example 1
   */
  qid: number;
  /**
   * 申請類型編號
   * @example 1
   */
  mid: number;
  /**
   * 申請類型
   * @example "數值地形模型"
   */
  type: string;
  /**
   * 地圖編號
   * @example "AF0239"
   */
  index: string;
  /**
   * 地圖附加編號
   * @example "臺北市大安區辛亥段一小段"
   */
  indexExtra: string;
  /**
   * 比例尺
   * @example "5000"
   */
  scale: string;
  /**
   * 座標系統
   * @example "TWD97(經緯度坐標)"
   */
  coord: string;
  /**
   * 地圖格式
   * @example "SHP"
   */
  format: string;
  /**
   * 地圖價錢
   * @example "2000"
   */
  price: number;
  /**
   * 地圖年分
   * @example "110"
   */
  year: string;
  /**
   * 地圖縣市
   * @example "臺北市"
   */
  city: string;
  /** 額外資訊 */
  note: string;
}

export interface QueryCreateDto {
  /**
   * 申請類型編號
   * @example 1
   */
  mid?: number;
  /**
   * 申請類型
   * @example "數值地形模型"
   */
  type?: string;
  /**
   * 地圖編號
   * @example "AF0239"
   */
  index?: string;
  /**
   * 地圖附加編號
   * @example "臺北市大安區辛亥段一小段"
   */
  indexExtra?: string;
  /**
   * 比例尺
   * @example "5000"
   */
  scale?: string;
  /**
   * 座標系統
   * @example "TWD97(經緯度坐標)"
   */
  coord?: string;
  /**
   * 地圖格式
   * @example "SHP"
   */
  format?: string;
  /**
   * 地圖價錢
   * @example "2000"
   */
  price?: number;
  /**
   * 地圖年分
   * @example "110"
   */
  year?: string;
  /**
   * 地圖縣市
   * @example "臺北市"
   */
  city?: string;
  /** 額外資訊 */
  note?: string;
}

export interface QueryUpdateDto {
  /**
   * 申請類型編號
   * @example 1
   */
  mid?: number;
  /**
   * 申請類型
   * @example "數值地形模型"
   */
  type?: string;
  /**
   * 地圖編號
   * @example "AF0239"
   */
  index?: string;
  /**
   * 地圖附加編號
   * @example "臺北市大安區辛亥段一小段"
   */
  indexExtra?: string;
  /**
   * 比例尺
   * @example "5000"
   */
  scale?: string;
  /**
   * 座標系統
   * @example "TWD97(經緯度坐標)"
   */
  coord?: string;
  /**
   * 地圖格式
   * @example "SHP"
   */
  format?: string;
  /**
   * 地圖價錢
   * @example "2000"
   */
  price?: number;
  /**
   * 地圖年分
   * @example "110"
   */
  year?: string;
  /**
   * 地圖縣市
   * @example "臺北市"
   */
  city?: string;
  /** 額外資訊 */
  note?: string;
}

export interface SignInRequestDto {
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email: string;
  /**
   * 使用者密碼
   * @example "thisIsMyPassword"
   */
  password: string;
}

export interface SignInResponseDto {
  /** JWT Token */
  access_token: string;
}

export interface SignUpRequestDto {
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email: string;
  /**
   * 使用者密碼
   * @example "thisIsMyPassword"
   */
  password: string;
  /**
   * 學校類別
   * @example "highschool"
   */
  category: SignUpRequestDtoCategoryEnum;
}

export interface BasicResponseDto {
  /** @example "success" */
  message: string;
}

export interface VerifyRequestDto {
  /**
   * 驗證碼
   * @example "72f0965b87d65926103b"
   */
  verificationcode: string;
}

export interface Student {
  /**
   * 姓名
   * @example "王曉明"
   */
  name: string;
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email: string;
  /**
   * 電話號碼
   * @example "0912345678"
   */
  phone: string;
  /**
   * 學校
   * @example "國立臺灣大學"
   */
  school: string;
  /**
   * 科系
   * @example "資訊工程學系"
   */
  subject: string;
  /**
   * 學生編號
   * @example 1
   */
  sid: number;
  /**
   * 年級
   * @example 1
   */
  year: number;
  /**
   * 是否為隊長
   * @example false
   */
  leader: boolean;
}

export interface StudentCreateDto {
  /**
   * 姓名
   * @example "王曉明"
   */
  name: string;
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email: string;
  /**
   * 電話號碼
   * @example "0912345678"
   */
  phone: string;
  /**
   * 學校
   * @example "國立臺灣大學"
   */
  school: string;
  /**
   * 科系
   * @example "資訊工程學系"
   */
  subject: string;
  /**
   * 年級
   * @example 1
   */
  year: number;
}

export interface StudentUpdateDto {
  /**
   * 姓名
   * @example "王曉明"
   */
  name?: string;
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email?: string;
  /**
   * 電話號碼
   * @example "0912345678"
   */
  phone?: string;
  /**
   * 學校
   * @example "國立臺灣大學"
   */
  school?: string;
  /**
   * 科系
   * @example "資訊工程學系"
   */
  subject?: string;
  /**
   * 年級
   * @example 1
   */
  year?: number;
  /**
   * 是否為隊長
   * @example false
   */
  leader?: boolean;
}

export interface Teacher {
  /**
   * 姓名
   * @example "王曉明"
   */
  name: string;
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email: string;
  /**
   * 電話號碼
   * @example "0912345678"
   */
  phone: string;
  /**
   * 學校
   * @example "國立臺灣大學"
   */
  school: string;
  /**
   * 科系
   * @example "資訊工程學系"
   */
  subject: string;
  /**
   * 老師編號
   * @example 1
   */
  tid: number;
}

export interface TeacherCreateDto {
  /**
   * 姓名
   * @example "王曉明"
   */
  name: string;
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email: string;
  /**
   * 電話號碼
   * @example "0912345678"
   */
  phone: string;
  /**
   * 學校
   * @example "國立臺灣大學"
   */
  school: string;
  /**
   * 科系
   * @example "資訊工程學系"
   */
  subject: string;
}

export interface TeacherUpdateDto {
  /**
   * 姓名
   * @example "王曉明"
   */
  name?: string;
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email?: string;
  /**
   * 電話號碼
   * @example "0912345678"
   */
  phone?: string;
  /**
   * 學校
   * @example "國立臺灣大學"
   */
  school?: string;
  /**
   * 科系
   * @example "資訊工程學系"
   */
  subject?: string;
}

export interface UploadFileRequestDto {
  /** @format binary */
  file: File;
}

export interface GroupDetailResponseDto {
  /**
   * 組別編號
   * @example 1
   */
  gid: number;
  /**
   * 電子信箱
   * @example "example@example.com"
   */
  email: string;
  /**
   * 學校類別
   * @example "highschool"
   */
  category: GroupDetailResponseDtoCategoryEnum;
  /**
   * 隊伍狀態
   * @example "created"
   */
  status: GroupDetailResponseDtoStatusEnum;
  /**
   * 隊伍身分
   * @example "user"
   */
  role: GroupDetailResponseDtoRoleEnum;
  /** 專題資訊 */
  project: Project;
  /** 學生資訊 */
  sids: Student[];
  /** 指導老師資訊 */
  tids: Teacher[];
}

/**
 * 學校類別
 * @example "highschool"
 */
export enum GroupResponseDtoCategoryEnum {
  Highschool = "highschool",
  College = "college",
}

/**
 * 隊伍狀態
 * @example "created"
 */
export enum GroupResponseDtoStatusEnum {
  Created = "created",
  Verify = "verify",
  Filledin = "filledin",
  Registered = "registered",
  Maprequested = "maprequested",
  Affidavitsigned = "affidavitsigned",
  Reportuploaded = "reportuploaded",
}

/**
 * 隊伍身分
 * @example "user"
 */
export enum GroupResponseDtoRoleEnum {
  User = "user",
  Admin = "admin",
}

/**
 * 隊伍狀態
 * @example "created"
 */
export enum GroupStatusRequestDtoStatusEnum {
  Created = "created",
  Verify = "verify",
  Filledin = "filledin",
  Registered = "registered",
  Maprequested = "maprequested",
  Affidavitsigned = "affidavitsigned",
  Reportuploaded = "reportuploaded",
}

/**
 * 學校類別
 * @example "highschool"
 */
export enum MetaSchoolCategoryEnum {
  Highschool = "highschool",
  College = "college",
}

/**
 * 地圖分類
 * @example "singlemap"
 */
export enum MetaMapCategoryEnum {
  Singlemap = "singlemap",
  Cadastralmap = "cadastralmap",
  Particularcadastralmap = "particularcadastralmap",
  Picturemap = "picturemap",
  Yearmap = "yearmap",
  Taiwanmap = "taiwanmap",
  Building3D = "building3d",
  Road3D = "road3d",
  Api = "api",
}

/**
 * 臺灣地圖分類
 * @example "traffic"
 */
export enum MetaMapTaiwancategoryEnum {
  Traffic = "traffic",
  RoadRail = "road_rail",
  BuildingArea = "building_area",
  Landmark = "landmark",
  WaterSystem = "water_system",
}

/**
 * 地圖種類
 * @example "traffic"
 */
export enum MetaTaiwanMapPriceCategoryEnum {
  Traffic = "traffic",
  RoadRail = "road_rail",
  BuildingArea = "building_area",
  Landmark = "landmark",
  WaterSystem = "water_system",
}

/**
 * 檔案類型
 * @example "affidavit"
 */
export enum AttachmentTypeEnum {
  Report = "report",
  Affidavit = "affidavit",
}

/**
 * 學校類別
 * @example "highschool"
 */
export enum SignUpRequestDtoCategoryEnum {
  Highschool = "highschool",
  College = "college",
}

/**
 * 學校類別
 * @example "highschool"
 */
export enum GroupDetailResponseDtoCategoryEnum {
  Highschool = "highschool",
  College = "college",
}

/**
 * 隊伍狀態
 * @example "created"
 */
export enum GroupDetailResponseDtoStatusEnum {
  Created = "created",
  Verify = "verify",
  Filledin = "filledin",
  Registered = "registered",
  Maprequested = "maprequested",
  Affidavitsigned = "affidavitsigned",
  Reportuploaded = "reportuploaded",
}

/**
 * 隊伍身分
 * @example "user"
 */
export enum GroupDetailResponseDtoRoleEnum {
  User = "user",
  Admin = "admin",
}

export enum GetSchoolsParamsTypeEnum {
  Highschool = "highschool",
  College = "college",
}

export enum GetPriceTypesParamsTypeEnum {
  Traffic = "traffic",
  RoadRail = "road_rail",
  BuildingArea = "building_area",
  Landmark = "landmark",
  WaterSystem = "water_system",
}

export enum GetFilesParamsTypeEnum {
  Report = "report",
  Affidavit = "affidavit",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title GIS API
 * @version 0.0.1
 * @contact
 *
 * 國土測繪圖資 GIS 競賽 API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  group = {
    /**
     * No description
     *
     * @tags Group
     * @name Get
     * @summary 獲得當前隊伍資訊
     * @request GET:/api/group
     * @secure
     */
    get: (params: RequestParams = {}) =>
      this.request<GroupResponseDto, any>({
        path: `/api/group`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group
     * @name UpdateStatus
     * @summary 更新隊伍狀態
     * @request PUT:/api/group/status
     * @secure
     */
    updateStatus: (data: GroupStatusRequestDto, params: RequestParams = {}) =>
      this.request<GroupResponseDto, any>({
        path: `/api/group/status`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  metadata = {
    /**
     * No description
     *
     * @tags Metadata
     * @name GetMeta
     * @request GET:/api/metadata
     */
    getMeta: (params: RequestParams = {}) =>
      this.request<MetaResponseDto, any>({
        path: `/api/metadata`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metadata
     * @name GetSchools
     * @request GET:/api/metadata/schools
     */
    getSchools: (
      query: {
        type: GetSchoolsParamsTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<MetaSchool[], any>({
        path: `/api/metadata/schools`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metadata
     * @name GetCities
     * @request GET:/api/metadata/schools/cities
     */
    getCities: (params: RequestParams = {}) =>
      this.request<MetaCity[], any>({
        path: `/api/metadata/schools/cities`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metadata
     * @name GetMapTypes
     * @request GET:/api/metadata/maps
     */
    getMapTypes: (params: RequestParams = {}) =>
      this.request<MetaMap[], any>({
        path: `/api/metadata/maps`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metadata
     * @name GetApiTypes
     * @request GET:/api/metadata/apis
     */
    getApiTypes: (params: RequestParams = {}) =>
      this.request<MetaApi[], any>({
        path: `/api/metadata/apis`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metadata
     * @name GetPriceTypes
     * @request GET:/api/metadata/prices
     */
    getPriceTypes: (
      query: {
        type: GetPriceTypesParamsTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<MetaTaiwanMapPrice[], any>({
        path: `/api/metadata/prices`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  project = {
    /**
     * No description
     *
     * @tags Project
     * @name Get
     * @summary 獲得當前專題
     * @request GET:/api/groups/{gid}/project
     * @secure
     */
    get: (gid: number, params: RequestParams = {}) =>
      this.request<Project, any>({
        path: `/api/groups/${gid}/project`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name Create
     * @summary 新增專題
     * @request POST:/api/groups/{gid}/project
     * @secure
     */
    create: (gid: number, data: ProjectCreateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/project`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name Update
     * @summary 修改專題資訊
     * @request PUT:/api/groups/{gid}/project/{pid}
     * @secure
     */
    update: (gid: number, pid: number, data: ProjectUpdateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/project/${pid}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name Delete
     * @summary 刪除專題
     * @request DELETE:/api/groups/{gid}/project/{pid}
     * @secure
     */
    delete: (gid: number, pid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/project/${pid}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  mapQuery = {
    /**
     * No description
     *
     * @tags MapQuery
     * @name Get
     * @summary 獲得所有申請
     * @request GET:/api/groups/{gid}/queries
     * @secure
     */
    get: (gid: number, params: RequestParams = {}) =>
      this.request<QueryMap[], any>({
        path: `/api/groups/${gid}/queries`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MapQuery
     * @name Create
     * @summary 新增申請
     * @request POST:/api/groups/{gid}/queries
     * @secure
     */
    create: (gid: number, data: QueryCreateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/queries`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MapQuery
     * @name Update
     * @summary 修改申請資訊
     * @request PUT:/api/groups/{gid}/queries/{qid}
     * @secure
     */
    update: (gid: number, qid: number, data: QueryUpdateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/queries/${qid}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MapQuery
     * @name Delete
     * @summary 刪除申請
     * @request DELETE:/api/groups/{gid}/queries/{qid}
     * @secure
     */
    delete: (gid: number, qid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/queries/${qid}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  authentications = {
    /**
     * No description
     *
     * @tags Authentications
     * @name SingIn
     * @summary 使用帳號密碼登入並取得 access_token
     * @request POST:/api/auth/login
     */
    singIn: (data: SignInRequestDto, params: RequestParams = {}) =>
      this.request<SignInResponseDto, any>({
        path: `/api/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentications
     * @name SingUp
     * @request POST:/api/auth/signup
     */
    singUp: (data: SignUpRequestDto, params: RequestParams = {}) =>
      this.request<BasicResponseDto, void>({
        path: `/api/auth/signup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentications
     * @name Verify
     * @request POST:/api/auth/verify
     * @secure
     */
    verify: (data: VerifyRequestDto, params: RequestParams = {}) =>
      this.request<BasicResponseDto, any>({
        path: `/api/auth/verify`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  student = {
    /**
     * No description
     *
     * @tags Student
     * @name Get
     * @summary 獲得所有學生
     * @request GET:/api/groups/{gid}/students
     * @secure
     */
    get: (gid: number, params: RequestParams = {}) =>
      this.request<Student[], any>({
        path: `/api/groups/${gid}/students`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name Create
     * @summary 新增學生
     * @request POST:/api/groups/{gid}/students
     * @secure
     */
    create: (gid: number, data: StudentCreateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/students`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name Update
     * @summary 修改學生資訊
     * @request PUT:/api/groups/{gid}/students/{sid}
     * @secure
     */
    update: (gid: number, sid: number, data: StudentUpdateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/students/${sid}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name Delete
     * @summary 刪除學生資訊
     * @request DELETE:/api/groups/{gid}/students/{sid}
     * @secure
     */
    delete: (gid: number, sid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/students/${sid}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student
     * @name SetLeader
     * @summary 設定為隊長
     * @request PUT:/api/groups/{gid}/students/{sid}/leader
     * @secure
     */
    setLeader: (gid: number, sid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/students/${sid}/leader`,
        method: "PUT",
        secure: true,
        ...params,
      }),
  };
  teacher = {
    /**
     * No description
     *
     * @tags Teacher
     * @name Get
     * @summary 獲得所有老師
     * @request GET:/api/groups/{gid}/teachers
     * @secure
     */
    get: (gid: number, params: RequestParams = {}) =>
      this.request<Teacher[], any>({
        path: `/api/groups/${gid}/teachers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name Create
     * @summary 新增老師
     * @request POST:/api/groups/{gid}/teachers
     * @secure
     */
    create: (gid: number, data: TeacherCreateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/teachers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name Update
     * @summary 修改老師資訊
     * @request PUT:/api/groups/{gid}/teachers/{tid}
     * @secure
     */
    update: (gid: number, tid: number, data: TeacherUpdateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/teachers/${tid}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teacher
     * @name Delete
     * @summary 刪除老師
     * @request DELETE:/api/groups/{gid}/teachers/{tid}
     * @secure
     */
    delete: (gid: number, tid: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/teachers/${tid}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  file = {
    /**
     * No description
     *
     * @tags File
     * @name GetAffidavit
     * @summary 獲得圖資申請書
     * @request GET:/api/groups/{gid}/files/affidavit
     * @secure
     */
    getAffidavit: (gid: number, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/groups/${gid}/files/affidavit`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name UploadAffidavit
     * @summary 上傳圖資申請書
     * @request POST:/api/groups/{gid}/files/affidavit
     * @secure
     */
    uploadAffidavit: (gid: number, data: UploadFileRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/files/affidavit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name GetSigned
     * @summary 獲得上傳的圖資申請書
     * @request GET:/api/groups/{gid}/files/signed
     * @secure
     */
    getSigned: (gid: number, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/groups/${gid}/files/signed`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name GetReport
     * @summary 獲得上傳的報告
     * @request GET:/api/groups/{gid}/files/report
     * @secure
     */
    getReport: (gid: number, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/groups/${gid}/files/report`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name UploadReport
     * @summary 上傳報告檔
     * @request POST:/api/groups/{gid}/files/report
     * @secure
     */
    uploadReport: (gid: number, data: UploadFileRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/groups/${gid}/files/report`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
  };
  admin = {
    /**
     * No description
     *
     * @tags Admin
     * @name GetQueryZip
     * @summary 獲得圖資申請表
     * @request GET:/api/query
     * @secure
     */
    getQueryZip: (params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/query`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name GetGroups
     * @summary 獲得所有隊伍資訊
     * @request GET:/api/groups
     * @secure
     */
    getGroups: (params: RequestParams = {}) =>
      this.request<GroupDetailResponseDto[], any>({
        path: `/api/groups`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name GetGroupListFile
     * @summary 獲得所有隊伍資訊清冊
     * @request GET:/api/groups/file
     * @secure
     */
    getGroupListFile: (params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/groups/file`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name GetFiles
     * @summary 獲得特定種類之所有檔案
     * @request GET:/api/files
     * @secure
     */
    getFiles: (
      query: {
        type: GetFilesParamsTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/files`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
}
