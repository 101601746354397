<template>
  <div class="container">
    <h3>註冊</h3>
    <form @submit="onSubmit" v-if="!isSubmitted">
      <div class="form-group">
        <label for="category">組別</label>
        <select
          v-bind="category"
          name="category"
          as="select"
          class="form-control form-control-lg"
        >
          <option value="highschool">
            {{ "高中職組" }}
          </option>
          <option value="college">
            {{ "大專院校組" }}
          </option>
        </select>
        <div class="error-feedback">{{ errors.category }}</div>
      </div>
      <div class="form-group">
        <label for="email"
          >電子信箱<br /><span class="important-message"
            >此為大會相關聯絡信箱，請務必填寫一個經常使用的團隊信箱</span
          ></label
        >
        <input
          v-bind="email"
          name="email"
          type="text"
          placeholder="example@google.com"
          class="form-control form-control-lg"
        />

        <div class="error-feedback">{{ errors.email }}</div>
      </div>
      <div class="form-group">
        <label for="password">密碼</label>
        <input
          v-bind="password"
          name="password"
          type="password"
          placeholder="password"
          class="form-control form-control-lg"
        />
        <div class="error-feedback">{{ errors.password }}</div>
      </div>
      <div class="form-group">
        <label for="password">再次確認密碼</label>
        <input
          v-bind="passwordConfirmed"
          name="passwordConfirmed"
          type="password"
          placeholder="password confirmed"
          class="form-control form-control-lg"
        />
        <div class="error-feedback">{{ errors.passwordConfirmed }}</div>
      </div>
      <div class="form-group">
        <button
          type="submit"
          class="btn btn-dark btn-lg btn-block"
          :disabled="isSubmitting"
        >
          <span
            v-show="isSubmitting"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>註冊</span>
        </button>
        <p class="forgot-password text-right">
          已經註冊過了?
          <router-link to="/register">登入</router-link>
        </p>
      </div>
    </form>
    <div v-if="errormsg" class="alert alert-danger" role="alert">
      {{ errormsg }}
    </div>
    <div v-if="message" class="alert alert-primary" role="alert">
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { SignUpRequestDtoCategoryEnum as GroupCategory } from "@/api/api";
import { apiClient } from "@/script/api-client";
import { store } from "@/store";
import { useForm } from "vee-validate";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();

// State Variables
const message = ref("");
const errormsg = ref("");
const isSubmitted = ref(false);

const schema = yup.object().shape({
  email: yup.string().required("請輸入信箱").email("信箱格式錯誤"),
  category: yup
    .mixed<GroupCategory>()
    .oneOf(Object.values(GroupCategory))
    .required(),
  password: yup.string().required("請輸入密碼").min(5, "請至少輸入 5 個字元"),
  passwordConfirmed: yup
    .string()
    .oneOf([yup.ref("password"), ""], "錯誤，請再次確認是否相同"),
});
const { errors, defineInputBinds, handleSubmit, isSubmitting } = useForm({
  validationSchema: schema,
});
const email = defineInputBinds("email", { validateOnInput: true });
const password = defineInputBinds("password", { validateOnInput: true });
const passwordConfirmed = defineInputBinds("passwordConfirmed", {
  validateOnInput: true,
});
const category = defineInputBinds("category", { validateOnInput: true });

const onSubmit = handleSubmit(async (values) => {
  await apiClient.authentications
    .singUp({
      email: values.email,
      password: values.password,
      category: values.category,
    })
    .then((response) => {
      if (response.status === 201) {
        isSubmitted.value = true;
        message.value = "註冊成功！ 3 秒後導向登入頁面";
        setTimeout(() => {
          router.push("/login");
        }, 2000);
      }
    })
    .catch((error) => {
      errormsg.value = error.error.message;
    });
});

onMounted(() => {
  if (store.getters.user.isLogIn) {
    router.push({ name: "home" });
  }
});
</script>

<style lang="scss" scoped>
.important-message {
  color: red;
}
</style>
