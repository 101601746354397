<template>
  <div class="container">
    <h3>
      指導老師
      <span :style="{ color: !isCompleted ? 'red' : 'black' }">{{
        isCompleted ? "&#10003;" : "&times;"
      }}</span>
    </h3>
    <table
      class="table table-light table-sm table-striped table-bordered tbody-hover"
    >
      <caption class="limitation">
        <ul>
          <li :class="{ success: hasOneTeacher, error: !hasOneTeacher }">
            有一位指導老師
            {{ hasOneTeacher ? "&#10003;" : "&times;" }}
          </li>
        </ul>
      </caption>
      <thead>
        <th scope="col">姓名</th>
        <th scope="col">信箱</th>
        <th scope="col">電話</th>
        <th scope="col">學校</th>
        <th scope="col">任教科系（任教科目）</th>
        <th scope="col" v-if="!isConfirmed">
          <button class="btn btn-primary btn-lg" @click="_addTeacher">
            新增
          </button>
        </th>
        <th scope="col"></th>
      </thead>
      <tbody>
        <tr v-for="teacher in teachers" :key="teacher.tid" scope="row">
          <td>{{ teacher?.name }}</td>
          <td>{{ teacher?.email }}</td>
          <td>{{ teacher?.phone }}</td>
          <td>{{ teacher?.school }}</td>
          <td>{{ teacher?.subject }}</td>
          <td v-if="!isConfirmed">
            <button
              class="btn btn-primary btn-lg"
              @click="_modifyTeacher(teacher)"
            >
              修改
            </button>
            <button
              class="btn btn-danger btn-lg"
              @click="deleteTeacher(teacher)"
            >
              刪除
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <BaseModal v-if="modalShow">
    <TeacherForm />
  </BaseModal>
</template>

<script setup lang="ts">
import { Teacher, TeacherCreateDto, TeacherUpdateDto } from "@/api/api";
import { apiClient } from "@/script/api-client";
import { injectDefault } from "@/script/common";
import { store } from "@/store";
import { computed, inject, provide, ref, watch } from "vue";
import { useRouter } from "vue-router";
import BaseModal from "./BaseModal.vue";
import TeacherForm from "./TeacherForm.vue";

const router = useRouter();

// Inject Section
const httpResponseErrorHandler = inject<(res: Response) => void>(
  "httpResponseErrorHandler",
  injectDefault
);
const setErrmsg = inject<(value: string) => void>("setErrmsg", injectDefault);
const isConfirmed = inject<boolean>("isConfirmed", false);
const timestamp = inject("timestamp", "");
const setTeacherCompleted = inject<(isCompleted: boolean) => void>(
  "setTeacherCompleted",
  injectDefault
);

// Load Data
const gid = computed(() => {
  return store.state.user.group?.gid || 0;
});
const teachers = ref<Teacher[]>([]);
const selectTeacher = ref<Teacher | null>(null);
function loadTeachers() {
  if (gid.value === 0) {
    setErrmsg("登入失敗，請重新整理頁面");
    return;
  }
  apiClient.getTeachers(gid.value).then((_teachers) => {
    teachers.value = _teachers;
    setErrmsg("");
  });
}
watch(
  [timestamp, gid],
  async () => {
    loadTeachers();
  },
  { immediate: true }
);

// States
const modalShow = ref(false);
const isProcessing = ref(false);
const isCompleted = computed(() => {
  const _isCompleted = hasOneTeacher.value;
  setTeacherCompleted(_isCompleted);
  return _isCompleted;
});
const hasOneTeacher = computed(() => {
  return teachers.value.length > 0;
});

// Methods
async function _addTeacher() {
  selectTeacher.value = null;
  modalShow.value = true;
}
function _modifyTeacher(Teacher: Teacher) {
  selectTeacher.value = Teacher;
  modalShow.value = true;
}

async function addTeacher(teacher: TeacherCreateDto) {
  await apiClient.teacher
    .create(gid.value, teacher)
    .catch(httpResponseErrorHandler);
  modalClose();
}
async function modifyTeacher(sid: number, teacher: TeacherUpdateDto) {
  await apiClient.teacher
    .update(gid.value, sid, teacher)
    .catch(httpResponseErrorHandler);
  modalClose();
}
async function deleteTeacher(Teacher: Teacher) {
  await apiClient.teacher
    .delete(gid.value, Teacher.tid)
    .catch(httpResponseErrorHandler);
  modalClose();
}
function modalClose() {
  selectTeacher.value = null;
  modalShow.value = false;
  isProcessing.value = false;
  router.push({
    name: "group",
    query: { t: Date.now() },
  });
}

// Provider
provide("isProcessing", isProcessing);
provide("selectTeacher", selectTeacher);
provide("modalClose", modalClose);
provide("addTeacher", addTeacher);
provide("modifyTeacher", modifyTeacher);
</script>

<style lang="scss">
.table {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #ced764;
}

.table thead th button {
  background-color: #0d6efd;
}
.limitation {
  ul {
    li {
      font-weight: bold;
    }
  }
}
.error {
  color: red;
}
</style>
