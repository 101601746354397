<template>
  <input
    :name="name"
    :value="checkedValue"
    :checked="checked"
    @change="handleChange"
    type="checkbox"
  />
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import { defineProps } from "vue";

const props = defineProps({
  name: String,
  checkedValue: String,
});

// The `name` is returned in a function because we want to make sure it stays reactive
// If the name changes you want `useField` to be able to pick it up
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const { handleChange, checked } = useField(() => props.name!, undefined, {
  type: "checkbox",
  checkedValue: props.checkedValue,
});
</script>
