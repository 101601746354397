<template>
  <BaseForm>
    <BaseTextInput name="name" displayText="姓名" placeholder="王小名" />
    <BaseTextInput
      name="email"
      displayText="電子信箱"
      placeholder="example@google.com"
    />
    <BaseTextInput
      name="phone"
      displayText="電話號碼"
      placeholder="0910123456"
    />
    <SchoolSelect />
    <BaseTextInput
      name="subject"
      displayText="任教科系（任教科目）"
      placeholder="地理、歷史、地理環境資源學系"
      caption="*以任教科系優先"
    />
  </BaseForm>
</template>

<script setup lang="ts">
import {
  MetaSchool,
  Teacher,
  TeacherCreateDto,
  TeacherUpdateDto,
} from "@/api/api";
import { injectDefault } from "@/script/common";
import { useForm } from "vee-validate";
import { Ref, computed, inject, provide, ref } from "vue";
import * as yup from "yup";
import BaseForm from "./BaseForm.vue";
import BaseTextInput from "./BaseTextInput.vue";
import SchoolSelect from "./SchoolSelect.vue";

// Inject Section
const selectTeacher = inject<Ref<Teacher>>("selectTeacher");
const isProcessing = inject<Ref<boolean>>("isProcessing", ref(false));
const addTeacher = inject<(query: TeacherCreateDto) => void>(
  "addTeacher",
  injectDefault
);
const modifyTeacher = inject<(sid: number, query: TeacherUpdateDto) => void>(
  "modifyTeacher",
  injectDefault
);
const selectedSchool = computed(() => {
  const select = selectTeacher?.value?.school;
  if (select) {
    return select;
  }
  const school = window.sessionStorage.getItem("school");
  if (school) {
    return (JSON.parse(school) as MetaSchool).name;
  }
  return null;
});

// Modify Section
const isModify = computed(() => {
  return selectTeacher?.value !== null;
});
// END

const schema = yup.object().shape({
  email: yup.string().required("請輸入信箱").email("信箱格式錯誤"),
  name: yup.string().required("請輸入姓名"),
  phone: yup.string().required("請輸入電話號碼"),
  school: yup.object().required("請選擇學校"),
  subject: yup.string().required("請輸入科系"),
});
const { handleSubmit, isSubmitting } = useForm({
  validationSchema: schema,
  initialValues: {
    email: selectTeacher?.value?.email || "",
    name: selectTeacher?.value?.name || "",
    phone: selectTeacher?.value?.phone || "",
    school: null as MetaSchool | null,
    subject: selectTeacher?.value?.subject || "",
  },
});

const onSubmit = handleSubmit(async (values) => {
  isProcessing.value = true;
  const { school, ...rest } = values;
  const payload = {
    ...rest,
    school: school?.name || "",
  };
  window.sessionStorage.setItem("school", JSON.stringify(school));
  if (isModify.value && selectTeacher) {
    const tid = selectTeacher.value.tid;
    modifyTeacher(tid, payload);
    return;
  }
  addTeacher(payload);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("onSubmit", onSubmit);
provide("isModify", isModify);
provide("selectedSchool", selectedSchool);
</script>
