<template>
  <BaseMapForm v-if="meta" :price="values.price">
    <MapTextInput name="index" :displayText="meta.indexName" />
    <MapTextInput name="scale" displayText="比例尺分母" :disabled="true" />
  </BaseMapForm>
</template>

<script setup lang="ts">
import { MetaMap, QueryCreateDto, QueryMap, QueryUpdateDto } from "@/api/api";
import { injectDefault } from "@/script/common";
import { useForm } from "vee-validate";
import { Ref, inject, provide, ref } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";
import MapTextInput from "./MapTextInput.vue";

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

// Form Section
const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required(`請輸入${meta?.value.indexName}`),
  scale: yup.string().required("請輸入比例尺分母"),
  price: yup.number().required("請輸入金額"),
});

const { values, isSubmitting, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    type: meta?.value.mapName,
    mid: meta?.value.id,
    index: selectQuery?.value?.index || "",
    scale: meta?.value.fixScale,
    price: meta?.value.price,
  },
});

const onSubmit = handleSubmit((values) => {
  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, values);
    return;
  }
  addQuery(values);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("onSubmit", onSubmit);
</script>
