<template>
  <div class="form-group row">
    <div class="col">
      <label for="city">學校縣市篩選</label>
      <select
        v-model="selectCity"
        name="city"
        class="form-control form-control-lg"
      >
        <option v-for="city in cities" :key="city.city" :value="city">
          {{ city.city }}
        </option>
      </select>
    </div>
    <div class="col">
      <label for="school">學校</label>
      <select
        v-model="value"
        name="school"
        class="form-control form-control-lg"
      >
        <option
          v-for="school in filteredSchools"
          :key="school.name"
          :value="school"
        >
          {{ school.name }}
        </option>
      </select>
    </div>
    <div class="error-feedback">{{ errorMessage }}</div>
  </div>
</template>

<script setup lang="ts">
import { GetSchoolsParamsTypeEnum, MetaCity, MetaSchool } from "@/api/api";
import { apiClient } from "@/script/api-client";
import { store } from "@/store";
import { useField } from "vee-validate";
import { Ref, computed, inject, ref, watch } from "vue";

const isReadyForModify = computed(() => {
  return fullSchools.value !== null && selectedSchool.value !== null;
});

const fullSchools = ref<MetaSchool[] | null>(null);
const filteredSchools = ref<MetaSchool[] | null>(null);
const cities = ref<MetaCity[] | null>(null);
const category = computed(() => {
  return store.state.user.group?.category;
});
function schoolNameCompare(front: MetaSchool, back: MetaSchool) {
  return back.name.localeCompare(front.name, "zh-hant");
}
function cityNameCompare(front: MetaCity, back: MetaCity) {
  return back.city.localeCompare(front.city, "zh-hant");
}
watch(
  category,
  (newCategory) => {
    if (!newCategory) {
      return;
    }
    const _category = newCategory as unknown as GetSchoolsParamsTypeEnum;
    apiClient.getMetaSchools(_category).then((_schools) => {
      _schools.sort(schoolNameCompare);
      fullSchools.value = _schools;
    });
    apiClient.getMetaCities().then((_cities) => {
      _cities.sort(cityNameCompare);
      cities.value = _cities;
    });
  },
  {
    immediate: true,
  }
);

const selectCity = ref<MetaCity | null>(null);
const { value, errorMessage } = useField<MetaSchool>("school");

const selectedSchool = inject<Ref<string | null>>("selectedSchool", ref(null));

watch(isReadyForModify, (isReady) => {
  if (!isReady) return;
  const school = fullSchools.value?.find(
    (school) => school.name === selectedSchool.value
  );
  if (!school) return;
  value.value = school;
});
watch(
  [selectCity, fullSchools],
  ([newCity, newFull]) => {
    filteredSchools.value = newFull;
    if (newCity) {
      filteredSchools.value =
        fullSchools.value?.filter((school) => school.city === newCity.city) ||
        null;
    }
  },
  {
    immediate: true,
  }
);
</script>
