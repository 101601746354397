<template>
  <div class="container">
    <h3>登入</h3>
    <form @submit="onSubmit">
      <div class="form-group">
        <label for="email">電子信箱</label>
        <input
          v-bind="email"
          name="email"
          type="text"
          placeholder="example@google.com"
          class="form-control form-control-lg"
        />
        <div class="error-feedback">{{ errors.email }}</div>
      </div>

      <div class="form-group">
        <label for="password">密碼</label>
        <input
          v-bind="password"
          name="password"
          type="password"
          placeholder="password"
          class="form-control form-control-lg"
        />
        <div class="error-feedback">{{ errors.password }}</div>
      </div>
      <div class="form-group">
        <button
          type="submit"
          class="btn btn-dark btn-lg btn-block"
          :disabled="isSubmitting"
        >
          <span
            v-show="isSubmitting"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>登入</span>
        </button>
        <p class="forgot-password text-right">
          還沒有註冊?
          <router-link to="/register">註冊</router-link>
        </p>
      </div>
    </form>
    <div v-if="errormsg" class="alert alert-danger" role="alert">
      {{ errormsg }}
    </div>
    <div v-if="message" class="alert alert-success" role="alert">
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiClient } from "@/script/api-client";
import { store } from "@/store";
import { useForm } from "vee-validate";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();

// State Variables
const message = ref("");
const errormsg = ref("");

// Form Variables
const schema = yup.object().shape({
  email: yup.string().required("email is required!").email("Email is invalid!"),
  password: yup.string().required("Password is required!"),
});
const { errors, defineInputBinds, handleSubmit, isSubmitting } = useForm({
  validationSchema: schema,
});
const email = defineInputBinds("email", { validateOnInput: true });
const password = defineInputBinds("password", { validateOnInput: true });

const onSubmit = handleSubmit(async (values) => {
  await apiClient.authentications
    .singIn({
      email: values.email,
      password: values.password,
    })
    .then((response) => {
      if (response.status === 201) {
        store.dispatch.user.logIn(response.data.access_token);
      }
      message.value = "登入成功！";
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      errormsg.value = error.error.message;
    });
});

onMounted(async () => {
  if (store.getters.user.isLogIn) {
    router.push({ name: "home" });
  }
});
</script>

<style lang="scss" scoped>
div + div {
  margin-top: 1rem;
}
</style>
