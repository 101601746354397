<template>
  <div class="container">
    <h3>您好，請問要登出嗎?</h3>

    <button
      type="submit"
      class="btn btn-primary btn-lg btn-block"
      @click="logOut"
    >
      登出
    </button>
    <button class="btn btn-danger btn-lg btn-block" @click="cancel">
      取消
    </button>
  </div>
</template>

<script setup lang="ts">
import { store } from "@/store";
import { onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

onMounted(() => {
  if (!store.getters.user.isLogIn) {
    router.push({ name: "login" });
  }
});

async function logOut() {
  await store.dispatch.user.logOut();
}

function cancel() {
  router.push("/");
}
</script>
