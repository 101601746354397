<template>
  <div class="form-group">
    <label>{{ displayText }}</label>
    <input
      v-model="value"
      :name="name"
      type="text"
      class="form-control form-control-lg"
      :disabled="disabled"
    />
    <div class="error-feedback">{{ errorMessage }}</div>
  </div>
</template>
<script setup lang="ts">
import { useField } from "vee-validate";
import { defineProps, watch } from "vue";

const props = defineProps({
  name: String,
  displayText: String,
  disabled: Boolean,
  value: String,
});
watch(
  () => props.value,
  (newValue) => {
    value.value = newValue;
  }
);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const { value, errorMessage } = useField(() => props.name!);
</script>
