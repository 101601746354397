import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "verify",
        name: "verify",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/VerifyView.vue"),
      },
      {
        path: "group",
        name: "group",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/GroupView.vue"),
      },
      {
        path: "map",
        name: "map",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/MapView.vue"),
      },
      {
        path: "affidavit",
        name: "affidavit",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AffidavitView.vue"),
      },
      {
        path: "report",
        name: "report",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ReportView.vue"),
      },
      {
        path: "end",
        name: "end",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/EndView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LogoutView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterView.vue"),
  },
  {
    path: "/admin/group",
    name: "admin-group",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminGroupView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
