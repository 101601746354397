<template>
  <div>
    <div>
      <h3 style="font-weight: bold; color: red">
        送出切結書之後才算正式完成圖資申請！
      </h3>
      <br />
      <button class="btn btn-primary btn-lg" @click.prevent="getAffidavit">
        下載圖資使用切結書
      </button>
    </div>
    <div class="form-group">
      <label> 上傳簽署完成之圖資使用切結書 </label>
      <input
        type="file"
        accept="application/pdf"
        class="fileinput form-control form-control-lg"
        @change="onFileChange($event)"
        ref="fileInputElement"
      />
      <button
        class="btn btn-success btn-lg"
        @click="uploadAffidavit"
        :class="{ disabled: !inputFile }"
      >
        <span
          v-show="isLoading"
          class="spinner-border spinner-border-sm"
        ></span>
        上傳
      </button>
      <p style="color: red">*檔案類型僅限 PDF 檔，且檔案大小請小於 1 MB</p>
    </div>
    <div>
      <div v-if="isUploaded" class="alert alert-success" role="info">
        上傳成功！請檢查是否正確？
        <button class="btn btn-success btn-lg" @click="getSignedAffidavit">
          <span
            v-show="isLoading"
            class="spinner-border spinner-border-sm"
          ></span>
          檢查
        </button>
      </div>
    </div>
    <button
      class="btn btn-success btn-lg"
      @click="confirm"
      :disabled="!isDocChecked"
    >
      <span v-show="isLoading" class="spinner-border spinner-border-sm"></span>
      送出切結書
    </button>
  </div>
  <MapTable
    :timestamp="timestamp"
    :isDisplayMode="true"
    v-model:isMapValidate="isMapValidate"
  />
</template>

<script setup lang="ts">
import { GroupResponseDtoStatusEnum as GroupStatus } from "@/api/api";
import MapTable from "@/components/MapTable.vue";
import { apiClient } from "@/script/api-client";
import { store } from "@/store";
import fileDownload from "js-file-download";
import { computed, defineEmits, onMounted, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const emits = defineEmits(["update", "updateStatus"]);
const errmsg = ref("");
const isMapValidate = ref(false);
const isLoading = ref(false);
const isUploaded = ref(false);
const isDocChecked = ref(false);

const timestamp = computed(() => {
  return route.query.t as string;
});

const inputFile = ref<File | null>(null);

const gid = computed(() => {
  return store.state.user.group?.gid || 0;
});

function onFileChange(event: Event) {
  if (event.target) {
    // @ts-expect-error: event target type not defined
    inputFile.value = event.target.files[0] as File;
  }
}
onMounted(async () => {
  await apiClient.getGroup().catch((error) => {
    if (error.error?.statusCode == 401) {
      store.dispatch.user.logOut();
      router.push({ name: "login" });
    } else {
      errmsg.value = error.message;
    }
  });
});

function uploadAffidavit() {
  if (!inputFile.value) {
    return;
  }
  console.log("uploadAffidavit");
  apiClient.file
    .uploadAffidavit(gid.value, { file: inputFile.value })
    .then(() => {
      isUploaded.value = true;
      alert("上傳成功！");
    })
    .catch((error) => {
      if (error.error?.statusCode == 500) {
        alert("伺服器錯誤，請稍後再試");
      } else if (error.error?.statusCode == 413) {
        alert("檔案太大，請將檔案大小壓縮過後再上傳");
      } else {
        errmsg.value = error.message;
      }
    });
}

async function getAffidavit() {
  await apiClient.file.getAffidavit(gid.value).then((response) => {
    const filename = (
      response.headers.get("content-disposition") || `filename=affidavit.docx`
    )
      .split("filename=")[1]
      .replace(/"/g, "");
    response.blob().then((blob) => {
      fileDownload(blob, filename);
    });
  });
}

async function getSignedAffidavit() {
  console.log("getSignedAffidavit");
  await apiClient.file.getSigned(gid.value).then((response) => {
    const filename = (
      response.headers.get("content-disposition") || `filename=affidavit.pdf`
    )
      .split("filename=")[1]
      .replace(/"/g, "");
    response.blob().then((blob) => {
      fileDownload(blob, filename);
    });
    isDocChecked.value = true;
  });
}

function confirm() {
  emits("updateStatus", GroupStatus.Affidavitsigned);
}

function setErrmsg(value: string) {
  errmsg.value = value;
}
// Provider
provide("setErrmsg", setErrmsg);
</script>
