<template>
  <div class="form-group">
    <label for="county">縣市</label>
    <select v-model="value" name="county" class="form-control form-control-lg">
      <option
        v-for="county in countyList"
        :key="county.countycode[0]"
        :value="county"
      >
        {{ county.countyname[0] }}
      </option>
    </select>
    <div class="error-feedback">{{ errorMessage }}</div>
  </div>
</template>

<script setup lang="ts">
import { nlscClient } from "@/script/nlsc-client";
import { ICountyItem } from "@/script/nlsc-model";
import { useField } from "vee-validate";
import { Ref, computed, defineProps, inject, ref, watch } from "vue";

const props = defineProps({
  ignore: {
    type: String,
  },
});

const isReadyForModify = computed(() => {
  return countyList.value !== null && selectedCounty.value !== null;
});

const countyList = ref<ICountyItem[] | null>(null);
nlscClient.getListCounty().then((countyItems) => {
  if (countyItems) {
    if (props.ignore) {
      countyItems = countyItems.filter(
        (county) => county.countyname[0] !== props.ignore
      );
    }
    countyItems.sort((a, b) =>
      a.countycode[0].localeCompare(b.countycode[0], "zh-Hant")
    );
    countyList.value = countyItems;
  }
});

const { value, errorMessage } = useField<ICountyItem>("county");

const selectedCounty = inject<Ref<ICountyItem | null>>(
  "selectedCounty",
  ref(null)
);

watch(isReadyForModify, (isReady) => {
  if (!isReady) return;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  value.value = selectedCounty.value!;
});
</script>
