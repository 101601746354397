<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand" href="https://contest.tgis.org.tw/" target="_blank"
      >競賽主站</a
    >
    <a class="navbar-brand" href="/">第六屆報名首頁</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" v-if="!isLogIn">
          <router-link to="/login" class="nav-link">Login</router-link>
        </li>
        <li class="nav-item" v-else>
          <router-link to="/logout" class="nav-link">Logout</router-link>
        </li>
        <li class="nav-item" v-if="isAdmin">
          <router-link :to="{ name: 'admin-group' }" class="nav-link"
            >後台管理頁面</router-link
          >
        </li>
      </ul>
    </div>
    <div class="flex clock">
      <a href="./6th_manual.pdf">操作說明</a>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { GroupResponseDtoRoleEnum as GroupRole } from "@/api/api";
import { store } from "@/store";
import { computed } from "vue";

const isLogIn = computed(() => store.getters.user.isLogIn);
// const isAdmin = computed(() => auhtStore.isAdmin);
const isAdmin = computed(
  () => store.state.user.group?.role === GroupRole.Admin
);
</script>

<style scoped lang="scss">
li a {
  text-decoration: none;
  color: white;
}
nav {
  padding: 3.5vh;
  background-color: #78b7f5;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  .flex {
    display: flex;
  }

  .clock {
    margin-right: 20px;
  }
}
</style>
