<template>
  <BaseForm>
    <BaseTextInput name="title" displayText="題目" placeholder="題目" />
    <div class="form-group">
      <label for="abstract"
        >摘要(<span style="color: red">限兩百字內</span>)</label
      >
      <input
        v-bind="abstract"
        name="abstract"
        as="textarea"
        placeholder="摘要"
        class="form-control form-control-lg"
        rows="10"
        cols="20"
      />
      <div class="error-feedback">{{ errors.abstract }}</div>
    </div>
  </BaseForm>
</template>

<script setup lang="ts">
import { Project, ProjectCreateDto, ProjectUpdateDto } from "@/api/api";
import { injectDefault } from "@/script/common";
import { useForm } from "vee-validate";
import { Ref, computed, inject, provide, ref } from "vue";
import * as yup from "yup";
import BaseForm from "./BaseForm.vue";
import BaseTextInput from "./BaseTextInput.vue";

// Inject Section
const selectProject = inject<Ref<Project>>("selectProject");
const isProcessing = inject<Ref<boolean>>("isProcessing", ref(false));
const addProject = inject<(query: ProjectCreateDto) => void>(
  "addProject",
  injectDefault
);
const modifyProject = inject<(sid: number, query: ProjectUpdateDto) => void>(
  "modifyProject",
  injectDefault
);

// Modify Section
const isModify = computed(() => {
  return selectProject?.value !== null;
});
// END

const schema = yup.object().shape({
  title: yup.string().required("請輸入標題"),
  abstract: yup.string().required("請輸入摘要").max(200),
});
const { errors, defineInputBinds, handleSubmit, isSubmitting } = useForm({
  validationSchema: schema,
  initialValues: {
    title: selectProject?.value?.title ?? "",
    abstract: selectProject?.value?.abstract ?? "",
  },
});
const abstract = defineInputBinds("abstract", { validateOnInput: true });

const onSubmit = handleSubmit(async (values) => {
  isProcessing.value = true;
  if (isModify.value && selectProject) {
    const pid = selectProject.value.pid;
    modifyProject(pid, values);
    return;
  }
  addProject(values);
});
// Provider
provide("isSubmitting", isSubmitting);
provide("onSubmit", onSubmit);
provide("isModify", isModify);
</script>
