import { GroupResponseDto, GroupResponseDtoRoleEnum } from "@/api/api";
import { defineModule } from "direct-vuex";
import { ActionContext } from "vuex";
import { moduleActionContext } from "..";
import { UserState } from "./state";

export const UserModule = defineModule({
  namespaced: true,
  state: (): UserState => ({
    accessToken: window.sessionStorage.getItem("accessToken") || "",
    group: undefined,
  }),
  getters: {
    token: (state) => {
      return state.accessToken;
    },
    isLogIn: (state) => {
      return state.accessToken !== "";
    },
    isAdmin: (state) => {
      return state.group?.role === GroupResponseDtoRoleEnum.Admin;
    },
  },
  mutations: {
    logIn(state, accessToken: string) {
      state.accessToken = accessToken;
      window.sessionStorage.setItem("accessToken", accessToken);
    },
    logOut(state) {
      state.accessToken = "";
      window.sessionStorage.clear();
      window.location.reload();
    },
    setGroup(state, group: GroupResponseDto) {
      state.group = group;
    },
  },
  actions: {
    logIn(context, accessToken: string) {
      const { commit } = userModActionContext(context);
      commit.logIn(accessToken);
    },
    logOut(context) {
      const { commit } = userModActionContext(context);
      commit.logOut();
    },
    setGroup(context, group: GroupResponseDto) {
      const { commit } = userModActionContext(context);
      commit.setGroup(group);
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userModActionContext = (context: ActionContext<any, any>) =>
  moduleActionContext(context, UserModule);
