<template>
  <form>
    <div class="form-group">
      <label for="type">圖資種類</label>
      <select
        v-bind="metaId"
        name="type"
        class="form-control form-control-lg"
        :disabled="isModify"
      >
        <option v-for="meta in mapTypes" :key="meta.id" :value="meta.id">
          {{ meta.mapName }}
        </option>
      </select>
      <div class="error-feedback">{{ errors.metaId }}</div>
    </div>
  </form>
  <div v-if="meta">
    <SingleMapForm v-if="meta.category === MetaMapCategoryEnum.Singlemap" />
    <CadastralMapForm
      v-if="meta.category === MetaMapCategoryEnum.Cadastralmap"
    />
    <ParticularCadastralMapForm
      v-if="meta.category === MetaMapCategoryEnum.Particularcadastralmap"
    />
    <PictureMapForm v-if="meta.category === MetaMapCategoryEnum.Picturemap" />
    <YearMapForm v-if="meta.category === MetaMapCategoryEnum.Yearmap" />
    <TaiwanMapForm v-if="meta.category === MetaMapCategoryEnum.Taiwanmap" />
    <Building3DForm v-if="meta.category === MetaMapCategoryEnum.Building3D" />
    <Road3DForm v-if="meta.category === MetaMapCategoryEnum.Road3D" />
    <ApiForm v-if="meta.category === MetaMapCategoryEnum.Api" />
  </div>
  <div v-if="!isMetaSelected">
    <button
      type="button"
      class="btn btn-danger btn-lg btn-block"
      @click="modalClose"
    >
      取消
    </button>
  </div>
</template>

<script setup lang="ts">
import { MetaMap, MetaMapCategoryEnum, QueryMap } from "@/api/api";
import { apiClient } from "@/script/api-client";
import { useForm } from "vee-validate";
import { Ref, computed, inject, provide, ref, watch } from "vue";
import * as yup from "yup";
import ApiForm from "./map-forms/ApiForm.vue";
import Building3DForm from "./map-forms/Building3DForm.vue";
import CadastralMapForm from "./map-forms/CadastralMapForm.vue";
import ParticularCadastralMapForm from "./map-forms/ParticularCadastralMapForm.vue";
import PictureMapForm from "./map-forms/PictureMapForm.vue";
import Road3DForm from "./map-forms/Road3DForm.vue";
import SingleMapForm from "./map-forms/SingleMapForm.vue";
import TaiwanMapForm from "./map-forms/TaiwanMapForm.vue";
import YearMapForm from "./map-forms/YearMapForm.vue";

// Inject Section
const modalClose = inject<(payload: MouseEvent) => void>("modalClose");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");

// Loading Data
const mapTypes = ref<MetaMap[]>([]);
apiClient.getMetaMaps().then((maps) => {
  mapTypes.value = maps;
});
function getMetaById(id?: number) {
  mapTypes.value.forEach((_meta) => {
    if (_meta.id === id) {
      meta.value = _meta;
    }
  });
}
// END

// Modify Section
const isModify = computed(() => {
  return selectQuery?.value !== null;
});
// END

// Form Section
const schema = yup.object().shape({
  metaId: yup.string().required("請選擇地圖種類"),
});
const { errors, defineInputBinds } = useForm({
  validationSchema: schema,
  initialValues: {
    metaId: selectQuery?.value?.mid,
  },
});
// Field
const metaId = defineInputBinds("metaId", { validateOnInput: true });
const meta = ref<MetaMap>({} as MetaMap);
const isMetaSelected = computed(() => {
  return metaId.value.value !== undefined;
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
watch([mapTypes, metaId], ([newMaps, newId]) => {
  getMetaById(newId?.value);
});

// Provider
provide("isModify", isModify);
provide("mapMeta", meta);
</script>
