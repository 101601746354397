import { UserModule } from "./user/module";
import { createDirectStore } from "direct-vuex";

export const { store, rootActionContext, moduleActionContext } =
  createDirectStore({
    modules: {
      user: UserModule,
    },
  });

export type AppStore = typeof store;
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}
