<template>
  <div>
    <div name="info" v-if="!isDisplayMode">
      <h3>注意事項</h3>
      <div class="note">
        <ul>
          <li>
            數值地形圖為10公尺網格資料(含加值成果)，以基本地形圖（比例尺五千分之一）圖幅範圍為資料供應單位。
          </li>
          <li>
            繪製圖資可至
            <a href="https://whgis-nlsc.moi.gov.tw" target="_blank"
              >國土測繪圖資e商城</a
            >
            查詢
          </li>
          <li>
            三維建物模型數值資料、三維道路模型數值資料可至
            <a href="https://3dmaps.nlsc.gov.tw/" target="_blank"
              >多維度國家空間資訊服務平臺</a
            >
            查詢
          </li>
          <li>三維建物模型數值資料臺北市圖資暫不提供申請</li>
          <li>
            API 介接可至
            <a
              href="https://maps.nlsc.gov.tw/S09SOA/homePage.action?Language=ZH"
              target="_blank"
              >圖資服務雲申請網路服務</a
            >
            查詢
          </li>
          <li>
            倘申請圖資所填資料（例如資料格式、坐標系統、圖資年份）非上開e商城所列供應資訊時，將由圖資供應單位就可確認圖資項目提供適合圖資，對於無法確認圖資項目者，則不供應
          </li>
          <li>
            如有圖資申請一式超過單項總額，請敘明特殊性及必要性，並主動洽競賽執行單位協助專案審查
          </li>
        </ul>
      </div>
    </div>
    <h3>
      圖資申請清單
      <span>{{ isValid ? "&#10003;" : "&times;" }}</span>
    </h3>
    <table
      class="table table-light table-sm table-striped table-bordered tbody-hover"
    >
      <caption
        class="limitation"
        :class="{ success: isValid, error: !isValid }"
      >
        <ul>
          <li>
            總預算不得超過 3 萬元（含） (目前：{{ totalPrice }} 元)
            {{ isValid ? "&#10003;" : "&times;" }}
          </li>
        </ul>
      </caption>
      <thead>
        <th scope="col" class="col-3">圖資種類</th>
        <th scope="col" class="col-2">
          資料名稱/圖幅號/地段代碼/縣市/鄉鎮市區/API代號
        </th>
        <th scope="col" class="col-1">比例尺分母</th>
        <th scope="col" class="col-1">坐標系統</th>
        <th scope="col" class="col-1">資料格式</th>
        <th scope="col" class="col-1">圖資產製年份</th>
        <th scope="col" class="col-1">申購費用（元）</th>
        <th scope="col" class="col-2" v-if="!isDisplayMode">
          <button
            class="btn btn-primary"
            :class="{ disabled: !isValid }"
            @click="_addQuery"
          >
            新增</button
          ><br />
        </th>
      </thead>
      <tbody>
        <tr v-for="map in queries" :key="map.mid" scope="row">
          <td>{{ map.type }}</td>
          <td>
            {{ map.index
            }}<span v-if="map.indexExtra"><br />({{ map.indexExtra }})</span>
          </td>
          <td>{{ map.scale }}</td>
          <td>{{ map.coord }}</td>
          <td>{{ map.format }}</td>
          <td>{{ map.year }}</td>
          <td>{{ map.price }}</td>
          <td v-if="!isDisplayMode">
            <button class="btn btn-primary" @click="_modifyQuery(map)">
              修改
            </button>
            <button class="btn btn-danger" @click="deleteQuery(map)">
              刪除
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <MapModal :isShow="modalShow" />
</template>

<script setup lang="ts">
import { QueryCreateDto, QueryMap, QueryUpdateDto } from "@/api/api";
import { apiClient } from "@/script/api-client";
import { injectDefault } from "@/script/common";
import { store } from "@/store";
import {
  computed,
  defineEmits,
  defineProps,
  inject,
  provide,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import MapModal from "./MapModal.vue";
const MAX_PRICE = 30000;
const router = useRouter();
const emits = defineEmits(["update:isMapValidate"]);
const props = defineProps({
  timestamp: {
    type: String,
    required: true,
  },
  isMapValidate: {
    type: Boolean,
    required: true,
  },
  isDisplayMode: {
    type: Boolean,
    default: false,
  },
});

// Messages
const setErrmsg = inject<(value: string) => void>("setErrmsg", injectDefault);

const isValid = computed(() => {
  const result = totalPrice.value < MAX_PRICE;
  emits("update:isMapValidate", result);
  return result;
});
const totalPrice = computed(() => {
  let total = 0;
  queries.value.map((query) => {
    total += query.price;
  });
  return total;
});

// Load Data
const gid = computed(() => {
  return store.state.user.group?.gid || 0;
});
const queries = ref<QueryMap[]>([]);
const selectQuery = ref<QueryMap | null>(null);
const modalShow = ref(false);
const isProcessing = ref(false);
function loadQueries() {
  if (!gid.value) {
    setErrmsg("登入失敗，請重新整理頁面");
    return;
  }
  apiClient.getQueries(gid.value).then((_queries) => {
    _queries.sort((a, b) => a.mid - b.mid);
    queries.value = _queries;
    setErrmsg("");
  });
}
watch(
  [() => props.timestamp, gid],
  async () => {
    loadQueries();
  },
  { immediate: true }
);

// Methods
function _addQuery() {
  selectQuery.value = null;
  modalShow.value = true;
}
function _modifyQuery(query: QueryMap) {
  selectQuery.value = query;
  modalShow.value = true;
}
function toMap() {
  router.push({
    name: "map",
    query: { t: Date.now() },
  });
}
async function addQuery(query: QueryCreateDto) {
  isProcessing.value = true;
  await apiClient.mapQuery.create(gid.value, query);
  modalClose();
}
async function modifyQuery(qid: number, query: QueryUpdateDto) {
  isProcessing.value = true;
  await apiClient.mapQuery.update(gid.value, qid, query);
  modalClose();
}
async function deleteQuery(query: QueryMap) {
  isProcessing.value = true;
  await apiClient.mapQuery.delete(gid.value, query.qid);
  modalClose();
}
function modalClose() {
  selectQuery.value = null;
  modalShow.value = false;
  isProcessing.value = false;
  toMap();
}

// Provider
provide("selectQuery", selectQuery);
provide("modalClose", modalClose);
provide("addQuery", addQuery);
provide("modifyQuery", modifyQuery);
provide("isProcessing", isProcessing);
</script>

<style lang="scss" scoped>
.table {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #ced764;
}

.table thead th button {
  background-color: #0d6efd;
}

.limitation {
  ul {
    li {
      font-weight: bold;
    }
  }
}
.success {
  color: green;
}
.error {
  color: red;
}

.note {
  color: red;
  text-align: left;
}
</style>
