<template>
  <BaseMapForm v-if="meta" :price="price">
    <CountySelect />
    <MapTextInput
      name="index"
      displayText="代碼"
      :value="countyCode"
      :disabled="true"
    />
    <MapTextInput name="year" displayText="繪製年份" />
    <MapTextInput name="format" displayText="資料格式" :disabled="true" />
  </BaseMapForm>
</template>

<script setup lang="ts">
import {
  GetPriceTypesParamsTypeEnum,
  MetaMap,
  MetaTaiwanMapPrice,
  QueryCreateDto,
  QueryMap,
  QueryUpdateDto,
} from "@/api/api";
import { apiClient } from "@/script/api-client";
import { injectDefault } from "@/script/common";
import { nlscClient } from "@/script/nlsc-client";
import { ICountyItem } from "@/script/nlsc-model";
import { useForm } from "vee-validate";
import { Ref, computed, inject, provide, ref, watch } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";
import CountySelect from "./CountySelect.vue";
import MapTextInput from "./MapTextInput.vue";

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required("請輸入索引號"),
  format: yup.string().required("請輸入資料格式"),
});
const { values, isSubmitting, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    type: meta?.value.mapName,
    mid: meta?.value.id,
    format: "SHP",
    county: null as ICountyItem | null,
    index: selectQuery?.value?.index || "",
    year: selectQuery?.value?.year || "",
  },
});

const taiwancategory = computed((): GetPriceTypesParamsTypeEnum | null => {
  if (!meta) return null;
  return meta.value.taiwancategory as unknown as GetPriceTypesParamsTypeEnum;
});
const prices = ref<MetaTaiwanMapPrice[]>([]);
if (taiwancategory.value)
  apiClient
    .getMetaPrices(taiwancategory.value)
    .then((res) => (prices.value = res))
    .then(() => {
      loadFromIndex(selectQuery?.value?.index);
    });
watch(taiwancategory, async (newCategory) => {
  if (!newCategory) return;
  prices.value = await apiClient.getMetaPrices(newCategory);
});

// Extra Index Section
const indexExtra = ref("");
const price = ref(0);
const countyCode = computed(() => {
  if (!values.county) return "";
  return values.county.countycode[0];
});
function loadExtraIndex(county?: ICountyItem | null) {
  if (!county) return;
  const countyName = county.countyname[0];
  indexExtra.value = countyName;
  price.value = prices.value.find((p) => p.county === countyName)?.price || 0;
}
watch(values, async (newValues) => {
  loadExtraIndex(newValues.county);
});

const selectedCounty = ref<ICountyItem | null>(null);
function loadFromIndex(countyCode?: string) {
  if (!countyCode) return;
  nlscClient.getCounty(countyCode).then((county) => {
    if (!county) return;
    selectedCounty.value = county;
    loadExtraIndex(county);
  });
}

const onSubmit = handleSubmit((values) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { county, ...rest } = values;
  const payload = { ...rest, indexExtra: indexExtra.value, price: price.value };
  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, payload);
    return;
  }
  addQuery(payload);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("selectedCounty", selectedCounty);
provide("onSubmit", onSubmit);
</script>
