import { GroupDetailResponseDtoStatusEnum as GroupStatus } from "@/api/api";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export function injectDefault<T>(payload: T): void {
  console.error("Error: injection not implemented");
}

export const GroupStatusI18n = [
  { name: GroupStatus.Verify, nameCN: "已開通帳號" },
  { name: GroupStatus.Filledin, nameCN: "已填寫報名資料" },
  { name: GroupStatus.Registered, nameCN: "已完成報名" },
  {
    name: GroupStatus.Maprequested,
    nameCN: "已送出圖資申請",
  },
  {
    name: GroupStatus.Affidavitsigned,
    nameCN: "已繳交圖資申請同意書",
  },
  {
    name: GroupStatus.Reportuploaded,
    nameCN: "已繳交成果報告",
  },
];
