<template>
  <form @submit.prevent="onSubmit">
    <slot></slot>
    <button
      type="submit"
      class="btn btn-primary btn-lg btn-block"
      :disabled="isSubmitting || isProcessing"
    >
      <span
        v-show="isSubmitting"
        class="spinner-border spinner-border-sm"
      ></span>
      <span>{{ isModify ? "修改" : "新增" }}</span>
    </button>
    <button
      type="button"
      class="btn btn-warning btn-lg btn-block"
      @click="modalClose"
    >
      取消
    </button>
  </form>
</template>

<script setup lang="ts">
import { Ref, inject } from "vue";

const isProcessing = inject<Ref<boolean>>("isProcessing");
const isModify = inject<Ref<boolean>>("isModify");
const isSubmitting = inject<Ref<boolean>>("isSubmitting");
const onSubmit = inject<(payload: Event) => void>("onSubmit");
const modalClose = inject<(payload: MouseEvent) => void>("modalClose");
</script>
