import axios from "axios";
import { parseStringPromise } from "xml2js";
import { ICountyItem, ISectionItem, ITownItem } from "./nlsc-model";
const NLSC_API = "https://api.nlsc.gov.tw/other";

class NlscClient {
  countyItem?: ICountyItem[];
  townItem: { [name: string]: ITownItem[] } = {};
  sectionItem: { [name: string]: ISectionItem[] } = {};

  async getListCounty(): Promise<ICountyItem[] | null | undefined> {
    if (!this.countyItem) {
      const response = await axios.get(NLSC_API + "/ListCounty");
      return parseStringPromise(response.data)
        .then((countyItems) => {
          this.countyItem = countyItems.countyItems.countyItem;
          return this.countyItem;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    }
    return this.countyItem;
  }

  async getCounty(countyCode: string) {
    return this.getListCounty().then((counties) => {
      const county = counties?.find((c) => c.countycode[0] === countyCode);
      return county;
    });
  }

  async getListTown(countyCode: string): Promise<ITownItem[] | null> {
    if (!this.townItem[countyCode]) {
      const response = await axios.get(NLSC_API + `/ListTown/${countyCode}`);
      return parseStringPromise(response.data)
        .then((townItems) => {
          this.townItem[countyCode] = townItems.townItems.townItem;
          return this.townItem[countyCode];
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    } else {
      return this.townItem[countyCode];
    }
  }

  async getTown(townCode: string) {
    const townList = await this.getListTown(townCode[0]);
    if (townList) {
      const town = townList.find((item) => {
        if (item.towncode[0] === townCode) {
          return item;
        }
      });
      return town;
    }
    return null;
  }

  async getTownName(townCode: string) {
    if (!townCode) return "";
    const countryList = await this.getListCounty();
    const townList = await this.getListTown(townCode[0]);
    if (countryList && townList) {
      const county = countryList.find((item) => {
        if (item.countycode[0] === townCode[0]) {
          return item;
        }
      });
      const town = townList.find((item) => {
        if (item.towncode[0] === townCode) {
          return item;
        }
      });
      if (county && town) {
        return county.countyname[0] + town.townname[0];
      }
    }
    return "";
  }

  async getListSection(townCode: string): Promise<ISectionItem[] | null> {
    if (!this.sectionItem[townCode]) {
      const response = await axios.get(
        NLSC_API + `/ListLandSection/${townCode[0]}/${townCode}`
      );
      return parseStringPromise(response.data)
        .then((sectionItems) => {
          this.sectionItem[townCode] = sectionItems.sectItems.sectItem;
          return this.sectionItem[townCode];
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    } else {
      return this.sectionItem[townCode];
    }
  }

  async getSection(townCode: string, sectionCode: string) {
    const sectionList = await this.getListSection(townCode);
    if (sectionList) {
      const section = sectionList.find((item) => {
        const code = item.office[0] + item.sectcode[0];
        if (code === sectionCode) {
          return item;
        }
      });
      return section;
    }
    return null;
  }
}

export const nlscClient = new NlscClient();
