<template>
  <div class="container">
    <h3>
      參賽學生
      <span :style="{ color: !isCompleted ? 'red' : 'black' }">{{
        isCompleted ? "&#10003;" : "&times;"
      }}</span>
    </h3>
    <table
      class="table table-light table-sm table-striped table-bordered tbody-hover"
    >
      <caption class="limitation">
        <ul>
          <li :class="{ success: hasOneStudent, error: !hasOneStudent }">
            至少一位學生
            {{ hasOneStudent ? "&#10003;" : "&times;" }}
          </li>
          <li :class="{ success: hasLeader, error: !hasLeader }">
            有一位隊長
            {{ hasLeader ? "&#10003;" : "&times;" }}
          </li>
        </ul>
      </caption>
      <thead>
        <th scope="col">身份</th>
        <th scope="col">姓名</th>
        <th scope="col">信箱</th>
        <th scope="col">電話</th>
        <th scope="col">學校</th>
        <th scope="col">科系</th>
        <th scope="col">年級</th>
        <th scope="col" v-if="!isConfirmed">
          <button
            class="btn btn-primary btn-lg"
            :class="{ disabled: students && students.length >= 3 }"
            @click="_addStudent"
          >
            新增</button
          ><br />
          至少一人，最多三人
        </th>
        <th scope="col" v-if="!isConfirmed"></th>
      </thead>
      <tbody>
        <tr v-for="student in students" :key="student.sid" scope="row">
          <td v-if="student.leader">隊長</td>
          <td v-else>隊員</td>
          <td>{{ student.name }}</td>
          <td>{{ student.email }}</td>
          <td>{{ student.phone }}</td>
          <td>{{ student.school }}</td>
          <td>{{ student.subject }}</td>
          <td>{{ student.year }}</td>
          <td v-if="!isConfirmed">
            <button
              class="btn btn-primary btn-lg"
              @click="_modifyStudent(student)"
            >
              修改
            </button>
            <button
              class="btn btn-danger btn-lg"
              @click="deleteStudent(student)"
            >
              刪除
            </button>
          </td>
          <td v-if="!isConfirmed">
            <button
              class="btn btn-primary btn-lg"
              @click="setLeader(student)"
              :class="{ disabled: student.leader }"
            >
              設為隊長
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <BaseModal v-if="modalShow">
    <StudentForm />
  </BaseModal>
</template>

<script setup lang="ts">
import { Student, StudentCreateDto, StudentUpdateDto } from "@/api/api";
import { apiClient } from "@/script/api-client";
import { injectDefault } from "@/script/common";
import { store } from "@/store";
import { computed, inject, provide, ref, watch } from "vue";
import { useRouter } from "vue-router";
import BaseModal from "./BaseModal.vue";
import StudentForm from "./StudentForm.vue";

const router = useRouter();

// Inject Section
const httpResponseErrorHandler = inject<(res: Response) => void>(
  "httpResponseErrorHandler",
  injectDefault
);
const setErrmsg = inject<(value: string) => void>("setErrmsg", injectDefault);
const isConfirmed = inject<boolean>("isConfirmed", false);
const timestamp = inject("timestamp", "");
const setStudentCompleted = inject<(isCompleted: boolean) => void>(
  "setStudentCompleted",
  injectDefault
);

// Load Data
const gid = computed(() => {
  return store.state.user.group?.gid || 0;
});
const students = ref<Student[]>([]);
const selectStudent = ref<Student | null>(null);
function loadStudents() {
  if (gid.value === 0) {
    setErrmsg("登入失敗，請重新整理頁面");
    return;
  }
  apiClient.getStudents(gid.value).then((_students) => {
    students.value = _students;
    setErrmsg("");
  });
}
watch(
  [timestamp, gid],
  async () => {
    loadStudents();
  },
  { immediate: true }
);

// States
const modalShow = ref(false);
const isProcessing = ref(false);
const isCompleted = computed(() => {
  const _isCompleted = hasOneStudent.value && hasLeader.value;
  setStudentCompleted(_isCompleted);
  return _isCompleted;
});
const hasOneStudent = computed(() => {
  return students.value.length > 0;
});
const hasLeader = computed(() => {
  return students.value.some((student) => student.leader);
});

// Methods
async function _addStudent() {
  selectStudent.value = null;
  modalShow.value = true;
}
function _modifyStudent(student: Student) {
  selectStudent.value = student;
  modalShow.value = true;
}

async function addStudent(student: StudentCreateDto) {
  await apiClient.student.create(gid.value, student);
  modalClose();
}
async function modifyStudent(sid: number, student: StudentUpdateDto) {
  await apiClient.student
    .update(gid.value, sid, student)
    .catch(httpResponseErrorHandler);
  modalClose();
}
async function deleteStudent(student: Student) {
  await apiClient.student
    .delete(gid.value, student.sid)
    .catch(httpResponseErrorHandler);
  modalClose();
}
async function setLeader(student: Student) {
  await apiClient.student
    .setLeader(gid.value, student.sid)
    .catch(httpResponseErrorHandler);
  modalClose();
}
function modalClose() {
  selectStudent.value = null;
  modalShow.value = false;
  isProcessing.value = false;
  router.push({
    name: "group",
    query: { t: Date.now() },
  });
}

// Provider
provide("isProcessing", isProcessing);
provide("selectStudent", selectStudent);
provide("modalClose", modalClose);
provide("addStudent", addStudent);
provide("modifyStudent", modifyStudent);
</script>

<style lang="scss" scoped>
.table {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.table thead th button {
  background-color: #0d6efd;
}
.limitation {
  ul {
    li {
      font-weight: bold;
    }
  }
}
.error {
  color: red;
}
</style>
