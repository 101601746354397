<template>
  <BaseMapForm v-if="meta" :price="values.price">
    <CountySelect />
    <TownSelect :countyCode="countyCode" />
    <SectionSelect :townCode="townCode" />
    <MapTextInput name="index" :displayText="meta.indexName" :value="index" />
    <MapTextInput
      name="scale"
      displayText="比例尺分母"
      :disabled="meta.fixScale !== null"
    />
    <div class="form-group" v-if="meta.hasCoord">
      <label for="coord">坐標系統</label>
      <select v-bind="coord" name="type" class="form-control form-control-lg">
        <option
          v-for="coord in coordinates"
          :key="coord.name"
          :value="coord.name"
        >
          {{ coord.name }}
        </option>
      </select>
      <div class="error-feedback">{{ errors.coord }}</div>
    </div>
    <div class="form-group" v-if="meta.hasFormat">
      <label for="format">資料格式</label>
      <select v-bind="format" name="type" class="form-control form-control-lg">
        <option
          v-for="format in formats"
          :key="format.name"
          :value="format.name"
        >
          {{ format.name }}
        </option>
      </select>
      <div class="error-feedback">{{ errors.format }}</div>
    </div>
    <MapTextInput name="year" displayText="圖資產製日期" v-if="meta.hasYear" />
  </BaseMapForm>
</template>

<script setup lang="ts">
import { MetaMap, QueryCreateDto, QueryMap, QueryUpdateDto } from "@/api/api";
import { injectDefault } from "@/script/common";
import { nlscClient } from "@/script/nlsc-client";
import { ICountyItem, ISectionItem, ITownItem } from "@/script/nlsc-model";
import { useForm } from "vee-validate";
import { Ref, inject, provide, ref, watch } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";
import CountySelect from "./CountySelect.vue";
import MapTextInput from "./MapTextInput.vue";
import SectionSelect from "./SectionSelect.vue";
import TownSelect from "./TownSelect.vue";

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

const coordinates = [
  { name: "TWD97(平面坐標)" },
  { name: "TWD97(經緯度坐標)" },
];
const formats = [{ name: "SHP" }, { name: "DXF" }];

const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required("請輸入索引號"),
  coord: yup.string().required("請輸入坐標系統"),
  format: yup.string().required("請輸入資料格式"),
  scale: yup.string().required("請輸入比例尺分母"),
  price: yup.number(),
});
const { values, errors, isSubmitting, handleSubmit, defineInputBinds } =
  useForm({
    validationSchema: schema,
    initialValues: {
      type: meta?.value.mapName,
      mid: meta?.value.id,
      price: meta?.value.price,
      county: null as ICountyItem | null,
      section: null as ISectionItem | null,
      town: null as ITownItem | null,
      index: selectQuery?.value?.index || "",
      coord: selectQuery?.value?.coord || coordinates[0].name,
      format: selectQuery?.value?.format || formats[0].name,
      scale: selectQuery?.value?.scale || "",
    },
  });

const coord = defineInputBinds("coord");
const format = defineInputBinds("format");

const countyCode = ref("");
watch(
  () => values.county,
  (newCounty) => {
    countyCode.value = newCounty?.countycode[0] || "";
    // if there is a change on county and it is different from the selected one
    // then remove the selected county and town
    if (
      selectedCounty.value &&
      selectedCounty.value.countycode[0] !== newCounty?.countycode[0]
    ) {
      selectedCounty.value = null;
      selectedTown.value = null;
      selectedSection.value = null;
    }
  }
);

const townCode = ref("");
watch(
  () => values.town,
  async (newTown) => {
    townCode.value = newTown?.towncode[0] || "";
    if (
      selectedTown.value &&
      selectedTown.value.towncode[0] !== newTown?.towncode[0]
    ) {
      selectedTown.value = null;
      selectedSection.value = null;
    }
  }
);

const indexExtra = ref("");
const index = ref("");
watch(
  () => values.section,
  (newSection) => {
    if (newSection === null) {
      indexExtra.value = "";
      index.value = "";
      return;
    }
    index.value = newSection.office[0] + newSection.sectcode[0];
    indexExtra.value = values.town?.townname + newSection.sectstr[0];
    if (
      selectedSection.value &&
      selectedSection.value.sectcode[0] !== newSection?.sectcode[0]
    ) {
      selectedSection.value = null;
    }
  }
);

const selectedCounty = ref<ICountyItem | null>(null);
const selectedTown = ref<ITownItem | null>(null);
const selectedSection = ref<ISectionItem | null>(null);
function loadFromIndex(index?: string, note?: string) {
  if (!index || !note) return;

  const countyCode = note.slice(0, 1);
  const townCode = note;
  const sectionCode = index;

  nlscClient.getCounty(countyCode).then((county) => {
    if (!county) return;
    selectedCounty.value = county;
  });
  nlscClient.getTown(townCode).then((town) => {
    if (!town) return;
    selectedTown.value = town;
  });
  nlscClient.getSection(townCode, sectionCode).then((section) => {
    if (!section) return;
    selectedSection.value = section;
  });
}
loadFromIndex(selectQuery?.value?.index, selectQuery?.value?.note);

const onSubmit = handleSubmit((values) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { county, town, section, ...rest } = values;
  const payload = {
    ...rest,
    indexExtra: indexExtra.value,
    note: values.town?.towncode[0] || "",
  };
  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, payload);
    return;
  }
  addQuery(payload);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("selectedCounty", selectedCounty);
provide("selectedTown", selectedTown);
provide("selectedSection", selectedSection);
provide("onSubmit", onSubmit);
</script>
