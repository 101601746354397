<template>
  <div class="container">
    <StudentTable />
    <TeacherTable />
    <ProjectTable />
    <button
      class="btn btn-success btn-lg"
      :class="{
        disabled:
          !isStudentCompleted || !isTeacherCompleted || !isProjectCompleted,
      }"
      @click="confirm"
    >
      確認報名
    </button>
    <button class="btn btn-danger btn-lg" @click="cancel" v-if="isConfirmed">
      取消
    </button>
    <button class="btn btn-primary btn-lg" v-if="!isConfirmed" @click="toMap">
      預填圖資申請
    </button>
    <div v-if="isConfirmed" style="color: red">確認報名後無法修改資料</div>
    <div style="color: red">當前報名截止時間：{{ resigerDeadline }}</div>
    <div>
      <div v-if="message" class="alert alert-primary" role="alert">
        {{ message }}
      </div>
      <div v-if="errmsg" class="alert alert-danger" role="alert">
        {{ errmsg }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  GroupResponseDtoStatusEnum as GroupStatus,
  MetaResponseDto,
} from "@/api/api";
import ProjectTable from "@/components/ProjectTable.vue";
import StudentTable from "@/components/StudentTable.vue";
import TeacherTable from "@/components/TeacherTable.vue";
import { apiClient } from "@/script/api-client";
import { store } from "@/store";
import { computed, defineEmits, onMounted, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const emits = defineEmits(["update", "updateStatus"]);

const message = ref("");
const errmsg = ref("");
const meta = ref<MetaResponseDto | null>(null);
const resigerDeadline = computed(() => {
  if (!meta.value?.register_deadline) return "";
  return new Date(meta.value.register_deadline).toLocaleString("zh-TW", {
    hour12: false,
  });
});

const isStudentCompleted = ref(false);
const isTeacherCompleted = ref(false);
const isProjectCompleted = ref(false);
const isConfirmed = computed(() => {
  return store.state.user.group?.status === GroupStatus.Filledin;
});
const timestamp = computed(() => {
  return route.query.t as string;
});

function confirm() {
  if (isConfirmed.value) {
    emits("updateStatus", GroupStatus.Registered);
  } else {
    emits("updateStatus", GroupStatus.Filledin);
  }
}

function cancel() {
  emits("updateStatus", GroupStatus.Verify);
}

function toMap() {
  router.push({ name: "map", query: { t: Date.now() } });
}

function setErrmsg(value: string) {
  errmsg.value = value;
}

onMounted(async () => {
  await apiClient.getGroup().catch((error) => {
    if (error.error?.statusCode == 401) {
      store.dispatch.user.logOut();
      router.push({ name: "login" });
    } else {
      errmsg.value = error.message;
    }
  });
  await apiClient.getMeta().then((_meta) => {
    meta.value = _meta;
  });
});

function setStudentCompleted(isCompleted: boolean) {
  isStudentCompleted.value = isCompleted;
}
function setTeacherCompleted(isCompleted: boolean) {
  isTeacherCompleted.value = isCompleted;
}
function setProjectCompleted(isCompleted: boolean) {
  isProjectCompleted.value = isCompleted;
}
// Provider
provide("setErrmsg", setErrmsg);
provide("isConfirmed", isConfirmed);
provide("timestamp", timestamp);
provide("setStudentCompleted", setStudentCompleted);
provide("setTeacherCompleted", setTeacherCompleted);
provide("setProjectCompleted", setProjectCompleted);
</script>
