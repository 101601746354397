<template>
  <BaseMapForm v-if="meta" :price="values.price">
    <CountySelect />
    <TownSelect :countyCode="countyCode" />
    <MapTextInput
      name="index"
      displayText="代碼"
      :value="index"
      :disabled="true"
    />
    <MapTextInput name="coord" displayText="坐標系統" v-if="meta.hasCoord" />
    <div class="form-group" v-if="meta.hasFormat">
      <label for="format">資料格式</label>
      <select v-bind="format" name="type" class="form-control form-control-lg">
        <option
          v-for="format in formats"
          :key="format.name"
          :value="format.name"
        >
          {{ format.name }}
        </option>
      </select>
      <div class="error-feedback">{{ errors.format }}</div>
    </div>
  </BaseMapForm>
</template>

<script setup lang="ts">
import { MetaMap, QueryCreateDto, QueryMap, QueryUpdateDto } from "@/api/api";
import { injectDefault } from "@/script/common";
import { nlscClient } from "@/script/nlsc-client";
import { ICountyItem, ITownItem } from "@/script/nlsc-model";
import { useForm } from "vee-validate";
import { Ref, inject, provide, ref, watch } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";
import CountySelect from "./CountySelect.vue";
import MapTextInput from "./MapTextInput.vue";
import TownSelect from "./TownSelect.vue";

const formats = [{ name: "SHP" }, { name: "DXF" }];

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required("請輸入索引號"),
  format: yup.string().required("請輸入資料格式"),
  price: yup.number(),
});
const { values, errors, isSubmitting, handleSubmit, defineInputBinds } =
  useForm({
    validationSchema: schema,
    initialValues: {
      type: meta?.value.mapName,
      mid: meta?.value.id,
      price: meta?.value.price,
      county: null as ICountyItem | null,
      town: null as ITownItem | null,
      index: selectQuery?.value?.index || "",
      coord: selectQuery?.value?.coord || "",
      format: selectQuery?.value?.format || formats[0].name,
    },
  });

const format = defineInputBinds("format");

const countyCode = ref("");
watch(
  () => values.county,
  (newCounty) => {
    countyCode.value = newCounty?.countycode[0] || "";
    // if there is a change on county and it is different from the selected one
    // then remove the selected county and town
    if (
      selectedCounty.value &&
      selectedCounty.value.countycode[0] !== newCounty?.countycode[0]
    ) {
      selectedCounty.value = null;
      selectedTown.value = null;
    }
  }
);

const indexExtra = ref("");
const index = ref("");
watch(
  () => values.town,
  async (newTown) => {
    // if twon is been set to null, then reset the index
    if (newTown === null) {
      indexExtra.value = "";
      index.value = "";
      return;
    }
    indexExtra.value = values.county?.countyname + newTown.townname[0];
    index.value = newTown.towncode[0];
    if (
      selectedTown.value &&
      selectedTown.value.towncode[0] !== newTown?.towncode[0]
    ) {
      selectedTown.value = null;
    }
  }
);

const selectedCounty = ref<ICountyItem | null>(null);
const selectedTown = ref<ITownItem | null>(null);
function loadFromIndex(index?: string) {
  if (!index) return;

  const countyCode = index.slice(0, 1);
  const townCode = index;

  nlscClient.getCounty(countyCode).then((county) => {
    if (!county) return;
    selectedCounty.value = county;
  });
  nlscClient.getTown(townCode).then((town) => {
    if (!town) return;
    selectedTown.value = town;
  });
}
loadFromIndex(selectQuery?.value?.index);

const onSubmit = handleSubmit((values) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { county, town, ...rest } = values;
  const payload = { ...rest, indexExtra: indexExtra.value };

  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, payload);
    return;
  }
  addQuery(payload);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("selectedCounty", selectedCounty);
provide("selectedTown", selectedTown);
provide("onSubmit", onSubmit);
</script>
