<template>
  <div>
    <div>
      <form @submit.prevent="uploadReport">
        <div class="form-group">
          <label> 上傳專題報告 </label>
          <input
            type="file"
            accept="application/pdf"
            class="fileinput form-control form-control-lg"
            @change="onFileChange($event)"
            ref="fileInputElement"
          />
          <button
            class="btn btn-success btn-lg"
            :class="{ disabled: !inputFile }"
          >
            <span
              v-show="isLoading"
              class="spinner-border spinner-border-sm"
            ></span>
            上傳
          </button>
          <p style="color: red">*檔案類型僅限 PDF 檔，且檔案大小請小於 30 MB</p>
          <div v-if="isUploaded" class="alert alert-success" role="info">
            上傳成功！
          </div>
        </div>
      </form>
      <form @submit.prevent="onSubmit">
        <div class="form-group">
          <label for="ytlink">實作影片 Youtube 連結</label>
          <input
            v-bind="ytlink"
            name="ytlink"
            type="text"
            placeholder="https://www.youtube.com/"
            class="form-control form-control-lg"
          />
          <div class="error-feedback">{{ errors.ytlink }}</div>
        </div>
        <button
          type="submit"
          class="btn btn-primary btn-lg btn-block"
          :disabled="isSubmitting"
        >
          <span
            v-show="isSubmitting"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>更新網址</span>
        </button>
      </form>
    </div>
    <div>
      <div>
        <h3>最新一次的報告</h3>
        <p>(上傳日期：{{ uploadTime }})</p>
        <button
          class="btn btn-success btn-lg"
          @click="getReport"
          :class="{ disabled: !isUploaded && !report }"
        >
          <span
            v-show="isLoading"
            class="spinner-border spinner-border-sm"
          ></span>
          下載報告
        </button>
        <a :href="project?.ytlink" target="_blank"
          ><button
            class="btn btn-success btn-lg"
            :class="{ disabled: !project?.ytlink }"
          >
            當前網址
          </button></a
        >
      </div>
    </div>
    <div class="form-group">
      <button
        v-if="isRollBackPossible"
        class="btn btn-info btn-lg"
        :class="{ disabled: !report }"
        @click="submit"
      >
        <span
          v-show="isLoading"
          class="spinner-border spinner-border-sm"
        ></span>
        成果繳交
      </button>
      <p class="important-message">
        提醒您，需按下「成果繳交」按鈕，才算完成競賽程序
      </p>
    </div>
    <div>
      <div v-if="errmsg" class="alert alert-danger" role="alert">
        {{ errmsg }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  AttachmentTypeEnum,
  GroupResponseDtoStatusEnum as GroupStatus,
  Project,
} from "@/api/api";
import { apiClient } from "@/script/api-client";
import { store } from "@/store";
import fileDownload from "js-file-download";
import { useForm } from "vee-validate";
import { computed, defineEmits, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();
const route = useRoute();
const emits = defineEmits(["update", "updateStatus"]);
const errmsg = ref("");
const isLoading = ref(false);
const isUploaded = ref(false);

const timestamp = computed(() => {
  return route.query.t as string;
});

const inputFile = ref<File | null>(null);
const isRollBackPossible = ref(true);

// Load Data
const gid = computed(() => {
  return store.state.user.group?.gid || 0;
});
const project = ref<Project | null>(null);
async function loadProject() {
  if (gid.value === 0) {
    errmsg.value = "登入失敗，請重新整理頁面";
    return;
  }
  return apiClient.getProject(gid.value).then((_project) => {
    project.value = _project;
    errmsg.value = "";
  });
}

const report = computed(() => {
  return project.value?.attachments?.filter(
    (file) => file.type === AttachmentTypeEnum.Report
  )[0];
});
const uploadTime = computed(() => {
  if (!report.value?.upload_at) return;
  return new Date(report.value.upload_at).toLocaleString("zh-TW", {
    hour12: false,
  });
});

const schema = yup.object().shape({
  ytlink: yup.string(),
});
const { errors, handleSubmit, isSubmitting, defineInputBinds } = useForm({
  validationSchema: schema,
});
const ytlink = defineInputBinds("ytlink", { validateOnInput: true });

const onSubmit = handleSubmit(async (values) => {
  if (!project.value?.pid) return;
  apiClient.project
    .update(gid.value, project.value.pid, {
      ytlink: values.ytlink,
    })
    .then(() => {
      alert("更新成功！");
      loadProject();
    });
});

watch(
  [timestamp, gid],
  async () => {
    await loadProject();
  },
  { immediate: true }
);

function onFileChange(event: Event) {
  if (event.target) {
    // @ts-expect-error: event target type not defined
    inputFile.value = event.target.files[0] as File;
  }
}

onMounted(async () => {
  await apiClient.getGroup().catch((error) => {
    if (error.error?.statusCode == 401) {
      store.dispatch.user.logOut();
      router.push({ name: "login" });
    } else {
      errmsg.value = error.message;
    }
  });
});

function uploadReport() {
  if (!inputFile.value) {
    return;
  }
  console.log("uploadReport");
  apiClient.file
    .uploadReport(gid.value, { file: inputFile.value })
    .then(() => {
      isUploaded.value = true;
      alert("上傳成功！");
      loadProject();
    })
    .catch((error) => {
      if (error.error?.statusCode == 500) {
        alert("伺服器錯誤，請稍後再試");
      } else if (error.error?.statusCode == 413) {
        alert("檔案太大，請將檔案大小壓縮過後再上傳");
      } else {
        errmsg.value = error.message;
      }
    });
}

async function getReport() {
  await apiClient.file.getReport(gid.value).then((response) => {
    const filename = (
      response.headers.get("content-disposition") || `filename=report.pdf`
    )
      .split("filename=")[1]
      .replace(/"/g, "");
    response.blob().then((blob) => {
      fileDownload(blob, filename);
    });
  });
}

function submit() {
  emits("updateStatus", GroupStatus.Reportuploaded);
}
</script>

<style lang="scss" scoped>
button + a {
  margin-left: 10px;
}
div + div {
  margin-top: 10px;
}
.important-message {
  color: red;
}
</style>
