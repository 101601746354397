<template>
  <div class="container">
    <h3>您已完成競賽作品繳交！謝謝參與！</h3>
    <div v-if="isRollBackPossible" class="form-group">
      <button class="btn btn-success btn-lg" @click="rollback">
        <span
          v-show="isLoading"
          class="spinner-border spinner-border-sm"
        ></span>
        返回重新繳交
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { GroupResponseDtoStatusEnum as GroupStatus } from "@/api/api";
import { defineEmits, ref } from "vue";

const emits = defineEmits(["update", "updateStatus"]);

const isRollBackPossible = ref(true);
const isLoading = ref(false);

function rollback() {
  emits("updateStatus", GroupStatus.Affidavitsigned);
}
</script>

<style lang="scss" scoped></style>
