<template>
  <div class="container">
    <br />
    <div>
      <button
        type="submit"
        class="btn btn-primary btn-lg btn-block"
        :disabled="isSubmitting"
        @click.prevent="getQueryZip"
      >
        <span
          v-show="isSubmitting"
          class="spinner-border spinner-border-sm"
        ></span>
        <span>下載圖資申請清單</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary btn-lg"
        :disabled="isSubmitting"
        @click.prevent="getGroupZip"
      >
        <span
          v-show="isSubmitting"
          class="spinner-border spinner-border-sm"
        ></span>
        <span>下載競賽隊伍清單</span>
      </button>
      <button
        type="submit"
        class="btn btn-success btn-lg"
        :disabled="isSubmitting"
        @click.prevent="getFile(FileType.Affidavit)"
      >
        <span
          v-show="isSubmitting"
          class="spinner-border spinner-border-sm"
        ></span>
        <span>下載所有圖資申請同意書</span>
      </button>
      <button
        type="submit"
        class="btn btn-success btn-lg"
        :disabled="isSubmitting"
        @click.prevent="getFile(FileType.Report)"
      >
        <span
          v-show="isSubmitting"
          class="spinner-border spinner-border-sm"
        ></span>
        <span>下載所有報告書</span>
      </button>
    </div>

    <form @submit="onSubmit">
      <h3>組別</h3>
      <select
        v-bind="category"
        name="category"
        as="select"
        class="form-control form-control-lg"
      >
        <option value="highschool">
          {{ "高中職組" }}
        </option>
        <option value="college">
          {{ "大專院校組" }}
        </option>
      </select>
      <h3>隊伍狀態篩選</h3>
      <table class="table table-light table-sm table-striped">
        <thead>
          <th v-for="status in GroupStatusI18n" :key="status.name">
            {{ status.nameCN }}
          </th>
        </thead>
        <tbody>
          <td v-for="status in GroupStatusI18n" :key="status.name">
            <CustomCheckbox name="groupStatus" :checked-value="status.name" />
            {{ status.name }}
          </td>
        </tbody>
      </table>
    </form>
    <GroupTable
      :category="values.category"
      :selectedStatus="values.groupStatus"
    />
  </div>
</template>

<script setup lang="ts">
import { GetFilesParamsTypeEnum as FileType } from "@/api/api";
import CustomCheckbox from "@/components/CustomCheckbox.vue";
import GroupTable from "@/components/GroupTable.vue";
import { apiClient } from "@/script/api-client";
import { GroupStatusI18n } from "@/script/common";
import { store } from "@/store";
import fileDownload from "js-file-download";
import { useForm } from "vee-validate";
import { computed, onMounted, provide } from "vue";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();
const timestamp = computed(() => {
  return Date.now();
});

const { values, defineInputBinds, handleSubmit, isSubmitting } = useForm({
  initialValues: {
    category: "highschool",
    groupStatus: [],
  },
  validationSchema: yup.object({
    groupStatus: yup.array().of(yup.string()),
  }),
});
const category = defineInputBinds("category", { validateOnInput: true });

const onSubmit = handleSubmit(async (values) => {
  console.log(values);
});

onMounted(() => {
  if (!store.getters.user.isLogIn) {
    console.log("Admin Download not log in");
    router.push({ name: "login" });
    return;
  }
  if (!store.getters.user.isAdmin) {
    console.log("Admin Download not admin");
    router.push({ name: "home" });
    return;
  }
});

async function getQueryZip() {
  isSubmitting.value = true;
  await apiClient.admin.getQueryZip().then((response) => {
    const filename = (
      response.headers.get("content-disposition") || `filename=query.zip`
    )
      .split("filename=")[1]
      .replace(/"/g, "");
    response.blob().then((blob) => {
      fileDownload(blob, filename);
    });
  });
  isSubmitting.value = false;
}

async function getGroupZip() {
  isSubmitting.value = true;
  await apiClient.admin.getGroupListFile().then((response) => {
    const filename = (
      response.headers.get("content-disposition") || `filename=GroupList.xlsx`
    )
      .split("filename=")[1]
      .replace(/"/g, "");
    response.blob().then((blob) => {
      fileDownload(blob, filename);
    });
  });
  isSubmitting.value = false;
}

async function getFile(type: FileType) {
  isSubmitting.value = true;
  await apiClient.admin
    .getFiles({
      type: type,
    })
    .then((response) => {
      const filename = (
        response.headers.get("content-disposition") || `filename=${type}.zip`
      )
        .split("filename=")[1]
        .replace(/"/g, "");
      response.blob().then((blob) => {
        fileDownload(blob, filename);
      });
    });
  isSubmitting.value = false;
}

provide("timestamp", timestamp);
</script>

<style lang="scss" scoped>
.table {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #ced764;
}

.table thead th button {
  background-color: #0d6efd;
}
</style>
