<template>
  <div class="home">
    <div v-if="!message">
      <div class="step-progress">
        <ul>
          <li :class="{ active: progress.created }"><div>開通帳號</div></li>
          <li :class="{ active: progress.validated }">
            <div>填寫隊伍資料</div>
          </li>
          <li :class="{ active: progress.filledin }"><div>確認報名</div></li>
          <li :class="{ active: progress.registered }"><div>圖資申請</div></li>
          <li :class="{ active: progress.maprequested }">
            <div>上傳圖資使用切結書</div>
          </li>
          <li :class="{ active: progress.affidavitsigned }">
            <div>繳交競賽成果作品</div>
          </li>
        </ul>
      </div>
      <div class="main-view container">
        <router-view @update="update" @updateStatus="updateStatus" />
      </div>
    </div>
    <div>
      <div v-if="message" class="alert alert-danger" role="alert">
        {{ message }}
      </div>
      <div v-if="errmsg" class="alert alert-danger" role="alert">
        {{ errmsg }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  GroupResponseDtoStatusEnum as GroupStatus,
  GroupStatusRequestDtoStatusEnum,
  HttpResponse,
} from "@/api/api";
import { apiClient } from "@/script/api-client";
import { store } from "@/store";
import { onMounted, provide, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

provide("updateStatus", updateStatus);

// State Variables
const message = ref("");
const errmsg = ref("");

// Progress Variables
const progress = ref({
  created: false,
  validated: false,
  filledin: false,
  registered: false,
  maprequested: false,
  affidavitsigned: false,
  reportuploaded: false,
});

onMounted(async () => {
  console.log("HomeView mounted");
  if (!store.getters.user.isLogIn) {
    console.log("HomeView not log in");
    router.push({ name: "login" });
    return;
  }
  await apiClient
    .getGroup()
    .then((group) => {
      console.log("HomeView getGroup");
      to(group.status);
    })
    .catch((error) => {
      console.log("HomeView getGroup Error");
      if (error.error?.statusCode == 401) {
        store.dispatch.user.logOut();
        router.push({ name: "login" });
      } else {
        errmsg.value = error.message;
      }
    });
});

function _to(name: string) {
  router.push({ name: name, query: { t: Date.now() } });
}

function to(status: string) {
  progressInit();
  switch (status) {
    case GroupStatus.Created:
      progress.value.created = true;
      _to("verify");
      break;
    case GroupStatus.Verify:
      progress.value.validated = true;
      _to("group");
      break;
    case GroupStatus.Filledin:
      progress.value.filledin = true;
      _to("group");
      break;
    case GroupStatus.Registered:
      progress.value.registered = true;
      _to("map");
      break;
    case GroupStatus.Maprequested:
      progress.value.maprequested = true;
      _to("affidavit");
      break;
    case GroupStatus.Affidavitsigned:
      progress.value.affidavitsigned = true;
      _to("report");
      break;
    case GroupStatus.Reportuploaded:
      progress.value.affidavitsigned = true;
      _to("end");
      break;
  }
}

async function update() {
  const group = await apiClient.getGroup();
  if (group) {
    to(group.status);
  }
}

function progressInit() {
  progress.value.created = false;
  progress.value.validated = false;
  progress.value.filledin = false;
  progress.value.registered = false;
  progress.value.maprequested = false;
  progress.value.affidavitsigned = false;
  progress.value.reportuploaded = false;
}

async function httpResponseErrorHandler(res: HttpResponse<void, Error>) {
  if (!(res instanceof Response)) throw res;
  let error = null;
  try {
    error = (await res.json()) as Error;
  } catch (e) {
    error = res.error;
  }
  const message = error.message as string | string[];
  if (message instanceof Array) {
    errmsg.value = message.join(", ");
  } else {
    errmsg.value = message;
  }
  alert(errmsg.value);
  return errmsg;
}

function updateStatus(_status: GroupStatus) {
  const status = _status as unknown as GroupStatusRequestDtoStatusEnum;
  apiClient.group
    .updateStatus({ status })
    .then(async () => {
      window.location.reload();
    })
    .catch(httpResponseErrorHandler);
}
provide("httpResponseErrorHandler", httpResponseErrorHandler);
</script>

<style lang="scss">
.step-progress {
  margin-top: 1rem;
  ul {
    list-style: none;
    display: flex;
    counter-reset: steps;
    li {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &::before {
        content: "";
        width: 3rem;
        height: 3rem;
        background: #bcbcbc;
        border-radius: 50%;
        margin: 1rem;
        display: block;
        counter-increment: steps;
        content: counter(steps);

        display: flex;
        color: white;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
      }
      &:nth-child(n + 2)::after {
        content: "";
        height: 0.3rem;
        width: 100%;
        background: #bcbcbc;
        position: absolute;
        left: -50%;
        z-index: -1;
      }
      // &.active:nth-child(n + 2)::after {
      //   background: #00bc9b;
      // }
      &.active::before {
        background: #00bc9b;
        box-shadow: 0 0 0 0.5rem rgb(255 255 255), 0 0 0 1rem #00bc9b;
      }
      div {
        color: black;
        position: absolute;
        bottom: -2rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.main-view {
  margin-top: 3rem;
}
button + button {
  margin-left: 1rem;
}
</style>
