<template>
  <BaseMapForm v-if="meta" :price="values.price">
    <CountySelect ignore="臺北市" />
    <MapTextInput
      name="index"
      displayText="代碼"
      :value="countyCode"
      :disabled="true"
    />
    <MapTextInput name="format" displayText="資料格式" />
  </BaseMapForm>
</template>

<script setup lang="ts">
import { MetaMap, QueryCreateDto, QueryMap, QueryUpdateDto } from "@/api/api";
import { injectDefault } from "@/script/common";
import { nlscClient } from "@/script/nlsc-client";
import { ICountyItem } from "@/script/nlsc-model";
import { useForm } from "vee-validate";
import { Ref, computed, inject, provide, ref, watch } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";
import CountySelect from "./CountySelect.vue";
import MapTextInput from "./MapTextInput.vue";

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required(`請輸入${meta?.value.indexName}`),
  format: yup.string().required("請輸入資料格式"),
});
const { values, isSubmitting, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    type: meta?.value.mapName,
    mid: meta?.value.id,
    county: null as ICountyItem | null,
    price: meta?.value.price,
    index: selectQuery?.value?.index || "",
    format: selectQuery?.value?.format || "",
  },
});

const indexExtra = ref("");
const countyCode = computed(() => {
  if (!values.county) return "";
  return values.county.countycode[0];
});
function loadExtraIndex(county?: ICountyItem | null) {
  if (!county) return;
  const countyName = county.countyname[0];
  indexExtra.value = countyName;
}
watch(values, async (newValues) => {
  loadExtraIndex(newValues.county);
});

const selectedCounty = ref<ICountyItem | null>(null);
function loadFromIndex(countyCode?: string) {
  if (!countyCode) return;
  nlscClient.getCounty(countyCode).then((county) => {
    if (!county) return;
    selectedCounty.value = county;
  });
}
loadFromIndex(selectQuery?.value?.index);

const onSubmit = handleSubmit((values) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { county, ...rest } = values;
  const payload = { ...rest, indexExtra: indexExtra.value };
  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, payload);
    return;
  }
  addQuery(payload);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("selectedCounty", selectedCounty);
provide("onSubmit", onSubmit);
</script>
