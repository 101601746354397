<template>
  <div class="container">
    <h3>開通帳號</h3>
    <form @submit="onSubmit">
      <div class="form-group">
        <label for="verificationcode">驗證碼</label>
        <input
          v-bind="verificationcode"
          name="verificationcode"
          type="text"
          class="form-control form-control-lg"
        />
        <span style="color: red">*請使用註冊信箱接收驗證碼</span><br />
        <div class="error-feedback">{{ errors.verificationcode }}</div>
      </div>
      <div class="form-group">
        <button
          type="submit"
          class="btn btn-dark btn-lg btn-block"
          :disabled="isSubmitting"
        >
          <span
            v-show="isSubmitting"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>開通</span>
        </button>
      </div>
    </form>
    <div v-if="errormsg" class="alert alert-danger" role="alert">
      {{ errormsg }}
    </div>
    <div v-if="message" class="alert alert-success" role="alert">
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiClient } from "@/script/api-client";
import { useForm } from "vee-validate";
import { defineEmits, ref } from "vue";
import * as yup from "yup";

const emits = defineEmits(["update"]);

// State Variables
const message = ref("");
const errormsg = ref("");

// Form Variables
const schema = yup.object().shape({
  verificationcode: yup.string().required("Verification code is required!"),
});
const { errors, defineInputBinds, handleSubmit, isSubmitting } = useForm({
  validationSchema: schema,
});
const verificationcode = defineInputBinds("verificationcode", {
  validateOnInput: true,
});

const onSubmit = handleSubmit(async (values) => {
  await apiClient.authentications
    .verify({
      verificationcode: values.verificationcode,
    })
    .then((response) => {
      if (response.status === 201) {
        message.value = "驗證成功！";
      }
      setTimeout(() => {
        emits("update");
      }, 1000);
    })
    .catch((error) => {
      errormsg.value = error.error.message;
    });
});
</script>
