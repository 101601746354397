<template>
  <div class="container">
    <h3>隊伍清單</h3>
    <table
      class="table table-light table-sm table-striped table-bordered tbody-hover"
    >
      <caption>
        資料查詢時間：{{
          timestampStr
        }}
      </caption>
      <thead>
        <th scope="col">參賽編號</th>
        <th scope="col">主題</th>
        <th scope="col">報名信箱</th>
        <th scope="col">隊長學校</th>
        <th scope="col">隊長姓名</th>
        <th scope="col">隊長信箱</th>
        <th scope="col">指導老師</th>
        <th scope="col">報名狀態</th>
        <th scope="col"></th>
        <th scope="col"></th>
      </thead>
      <tbody>
        <tr v-for="group in filteredGroups" :key="group.gid" scope="row">
          <td width="100px">{{ group.project?.pcode }}</td>
          <td width="300px">{{ group.project?.title }}</td>
          <td width="300px">{{ group.email }}</td>
          <td width="200px">
            <span v-for="student in group.sids" :key="student.sid">{{
              student.leader ? student.school : ""
            }}</span>
          </td>
          <td width="150px">
            <span v-for="student in group.sids" :key="student.sid">{{
              student.leader ? student.name : ""
            }}</span>
          </td>
          <td width="200px">
            <span v-for="student in group.sids" :key="student.sid">{{
              student.leader ? student.email : ""
            }}</span>
          </td>
          <td width="150px">
            <span v-for="teacher in group.tids" :key="teacher.tid"
              >{{ teacher.name }}<br
            /></span>
          </td>
          <td width="150px">
            {{
              GroupStatusI18n.filter(
                (status) => status.name === group.status
              )[0].nameCN
            }}
          </td>
          <td width="150px">
            <button
              class="btn btn-success btn-sm"
              @click="getSignedAffidavit(group.gid)"
              :class="{
                disabled:
                  !group.project ||
                  !group.project.attachments ||
                  group.project.attachments.filter(
                    (attachment) =>
                      attachment.type === AttachmentTypeEnum.Affidavit
                  ).length === 0,
              }"
            >
              <span
                v-show="isProcessing"
                class="spinner-border spinner-border-sm"
              ></span>
              下載同意書
            </button>
            <br />
            <button
              class="btn btn-success btn-sm"
              @click="getReport(group.gid)"
              :class="{
                disabled:
                  !group.project ||
                  !group.project.attachments ||
                  group.project.attachments.filter(
                    (attachment) =>
                      attachment.type === AttachmentTypeEnum.Report
                  ).length === 0,
              }"
            >
              <span
                v-show="isProcessing"
                class="spinner-border spinner-border-sm"
              ></span>
              下載報告書
            </button>
            <br />
            <a :href="group.project?.ytlink" target="_blank"
              ><button
                class="btn btn-danger btn-sm"
                :class="{ disabled: !group.project?.ytlink }"
              >
                YT 網址
              </button></a
            >
          </td>
          <td width="150px">
            <button
              class="btn btn-warning btn-sm disabled"
              @click="changeStatus"
            >
              <span
                v-show="isProcessing"
                class="spinner-border spinner-border-sm"
              ></span>
              修改狀態(dev)
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import {
  AttachmentTypeEnum,
  GroupDetailResponseDto,
  GroupDetailResponseDtoRoleEnum as GroupRole,
} from "@/api/api";
import { apiClient } from "@/script/api-client";
import { GroupStatusI18n } from "@/script/common";
import fileDownload from "js-file-download";
import { PropType, computed, defineProps, inject, ref, watch } from "vue";

const props = defineProps({
  category: {
    type: String,
    required: true,
  },
  selectedStatus: {
    type: Array as PropType<string[]>,
    required: true,
    default: () => [],
  },
});

const timestamp_inject = inject(
  "timestamp",
  computed(() => Date.now())
);
const timestamp_load = ref(0);
const timestampStr = computed(() => {
  return new Date(timestamp_load.value).toLocaleString("zh-TW", {
    hour12: false,
  });
});

const groups = ref<GroupDetailResponseDto[]>([]);
const filteredGroups = computed(() => {
  return groups.value.filter((group) => group.role === GroupRole.User);
});
function loadGroups() {
  apiClient.getGroupsDetail().then((_groups) => {
    groups.value = _groups.filter((group) => group.category === props.category);
    if (props.selectedStatus.length > 0) {
      groups.value = groups.value.filter((group) =>
        props.selectedStatus.includes(group.status)
      );
    }

    timestamp_load.value = Date.now();
  });
}
watch(
  [timestamp_inject, () => props.category, () => props.selectedStatus],
  async () => {
    loadGroups();
  },
  { immediate: true }
);

// Method
async function getReport(gid: number) {
  await apiClient.file.getReport(gid).then((response) => {
    const filename = (
      response.headers.get("content-disposition") ||
      `filename=${gid}-report.pdf`
    )
      .split("filename=")[1]
      .replace(/"/g, "");
    response.blob().then((blob) => {
      fileDownload(blob, filename);
    });
  });
}

async function getSignedAffidavit(gid: number) {
  await apiClient.file.getSigned(gid).then((response) => {
    const filename = (
      response.headers.get("content-disposition") ||
      `filename=${gid}-affidavit.pdf`
    )
      .split("filename=")[1]
      .replace(/"/g, "");
    response.blob().then((blob) => {
      fileDownload(blob, filename);
    });
  });
}

async function changeStatus() {
  console.log("changeStatus");
}

// States
const isProcessing = ref(false);
</script>

<style lang="scss" scoped>
.table {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #ced764;
}

.table thead th button {
  background-color: #0d6efd;
}
.limitation {
  ul {
    li {
      font-weight: bold;
    }
  }
}
.error {
  color: red;
}
</style>
