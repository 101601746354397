<template>
  <div class="form-group">
    <label for="county">鄉鎮市區</label>
    <select v-model="value" name="town" class="form-control form-control-lg">
      <option v-for="town in townList" :key="town.towncode[0]" :value="town">
        {{ town.townname[0] }}
      </option>
    </select>
    <div class="error-feedback">{{ errorMessage }}</div>
  </div>
</template>

<script setup lang="ts">
import { nlscClient } from "@/script/nlsc-client";
import { ITownItem } from "@/script/nlsc-model";
import { useField } from "vee-validate";
import { Ref, computed, defineProps, inject, ref, watch } from "vue";

const props = defineProps({
  countyCode: {
    type: String,
    required: true,
  },
});

const isReadyForModify = computed(() => {
  return townList.value !== null && selectedTown.value !== null;
});

const townList = ref<ITownItem[] | null>(null);
watch(
  () => props.countyCode,
  (newCountyCode) => {
    if (newCountyCode === value.value?.towncode[0]) return;
    value.value = null;
    nlscClient.getListTown(newCountyCode).then((townItems) => {
      if (townItems) {
        townList.value = townItems;
      }
    });
  }
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const { value, errorMessage } = useField<ITownItem | null>("town");

const selectedTown = inject<Ref<ITownItem | null>>("selectedTown", ref(null));
watch(isReadyForModify, (isReady) => {
  if (!isReady) return;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  value.value = selectedTown.value!;
});
</script>
