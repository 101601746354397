<template>
  <!-- :value="section.office[0] + section.sectcode[0]" -->
  <div class="form-group">
    <label for="section">地段</label>
    <select v-model="value" name="section" class="form-control form-control-lg">
      <option
        v-for="section in sectionList"
        :key="section.sectcode[0]"
        :value="section"
      >
        {{ section.sectstr[0] }}
      </option>
    </select>
    <div class="error-feedback">{{ errorMessage }}</div>
  </div>
</template>

<script setup lang="ts">
import { nlscClient } from "@/script/nlsc-client";
import { ISectionItem } from "@/script/nlsc-model";
import { useField } from "vee-validate";
import { Ref, computed, defineProps, inject, ref, watch } from "vue";

const props = defineProps({
  townCode: {
    type: String,
    required: true,
  },
});

const isReadyForModify = computed(() => {
  return sectionList.value !== null && selectedSection.value !== null;
});

const sectionList = ref<ISectionItem[] | null>(null);
watch(
  () => props.townCode,
  (newTownCode) => {
    value.value = null;
    if (newTownCode === "") {
      sectionList.value = null;
      return;
    }
    nlscClient.getListSection(newTownCode).then((sectionItems) => {
      if (sectionItems) {
        sectionList.value = sectionItems;
      }
    });
  }
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const { value, errorMessage } = useField("section");

const selectedSection = inject<Ref<ISectionItem | null>>(
  "selectedSection",
  ref(null)
);
watch(isReadyForModify, (isReady) => {
  if (!isReady) return;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  value.value = selectedSection.value!;
});
</script>
