<template>
  <div>
    <div v-if="errmsg" class="alert alert-danger" role="alert">
      {{ errmsg }}
    </div>
  </div>
  <MapTable :timestamp="timestamp" v-model:isMapValidate="isMapValidate" />
  <button
    class="btn btn-success btn-lg"
    @click="confirm"
    :class="{ disabled: !isMapValidate }"
    v-if="isRegistered"
  >
    確認送出申請
  </button>
  <button class="btn btn-success btn-lg" @click="toGroup" v-if="!isRegistered">
    返回報名
  </button>
  <div v-if="isRegistered" style="color: red">
    只能申請一次且送出申請後無法修改資料
  </div>
</template>

<script setup lang="ts">
import { GroupResponseDtoStatusEnum as GroupStatus } from "@/api/api";
import { apiClient } from "@/script/api-client";
import { store } from "@/store";
import { computed, defineEmits, onMounted, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import MapTable from "../components/MapTable.vue";

const router = useRouter();
const route = useRoute();
const emits = defineEmits(["update", "updateStatus"]);
const errmsg = ref("");

const isRegistered = computed(() => {
  return store.state.user.group?.status === GroupStatus.Registered;
});
const isMapValidate = ref(false);

const timestamp = computed(() => {
  return route.query.t as string;
});

function confirm() {
  emits("updateStatus", GroupStatus.Maprequested);
}

function toGroup() {
  router.push({ name: "group", query: { t: Date.now() } });
}

function setErrmsg(value: string) {
  errmsg.value = value;
}

onMounted(async () => {
  await apiClient.getGroup().catch((error) => {
    if (error.error?.statusCode == 401) {
      store.dispatch.user.logOut();
      router.push({ name: "login" });
    } else {
      errmsg.value = error.message;
    }
  });
});

// Provider
provide("setErrmsg", setErrmsg);
</script>
