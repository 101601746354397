<template>
  <div class="container">
    <h3>
      題目摘要
      <span :style="{ color: !isCompleted ? 'red' : 'black' }">{{
        isCompleted ? "&#10003;" : "&times;"
      }}</span>
    </h3>
    <table
      class="table table-light table-sm table-striped table-bordered tbody-hover"
    >
      <caption class="limitation">
        <ul>
          <li :class="{ success: project, error: !project }">
            題目摘要
            {{ project ? "&#10003;" : "&times;" }}
          </li>
        </ul>
      </caption>
      <thead>
        <th scope="col">參賽編號</th>
        <th scope="col">標題</th>
        <th scope="col">摘要</th>
        <th scope="col" v-if="!isConfirmed">
          <button
            class="btn btn-primary btn-lg"
            :class="{ disabled: project }"
            @click="_addProject"
          >
            新增
          </button>
        </th>
        <th scope="col"></th>
      </thead>
      <tbody>
        <tr scope="row" v-if="project">
          <td>{{ project?.pcode }}</td>
          <td>{{ project?.title }}</td>
          <td>{{ project?.abstract }}</td>
          <td v-if="!isConfirmed">
            <button
              class="btn btn-primary btn-lg"
              @click="_modifyProject(project)"
            >
              修改
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <BaseModal v-if="modalShow">
    <ProjectForm />
  </BaseModal>
</template>

<script setup lang="ts">
import { Project, ProjectCreateDto, ProjectUpdateDto } from "@/api/api";
import { apiClient } from "@/script/api-client";
import { injectDefault } from "@/script/common";
import { store } from "@/store";
import { computed, inject, provide, ref, watch } from "vue";
import { useRouter } from "vue-router";
import BaseModal from "./BaseModal.vue";
import ProjectForm from "./ProjectForm.vue";

const router = useRouter();

// Inject Section
const httpResponseErrorHandler = inject<(res: Response) => void>(
  "httpResponseErrorHandler",
  injectDefault
);
const setErrmsg = inject<(value: string) => void>("setErrmsg", injectDefault);
const isConfirmed = inject<boolean>("isConfirmed", false);
const timestamp = inject("timestamp", "");
const setProjectCompleted = inject<(isCompleted: boolean) => void>(
  "setProjectCompleted",
  injectDefault
);

// Load Data
const gid = computed(() => {
  return store.state.user.group?.gid || 0;
});
const project = ref<Project>();
const selectProject = ref<Project | null>(null);
function loadProject() {
  if (gid.value === 0) {
    setErrmsg("登入失敗，請重新整理頁面");
    return;
  }
  apiClient.getProject(gid.value).then((_project) => {
    project.value = _project;
    setErrmsg("");
  });
}
watch(
  [timestamp, gid],
  async () => {
    loadProject();
  },
  { immediate: true }
);

// States
const modalShow = ref(false);
const isProcessing = ref(false);
const isCompleted = computed(() => {
  const _isCompleted = hasOneProject.value;
  setProjectCompleted(_isCompleted);
  return _isCompleted;
});
const hasOneProject = computed(() => {
  return project.value !== undefined;
});

// Methods
async function _addProject() {
  selectProject.value = null;
  modalShow.value = true;
}
function _modifyProject(Project: Project) {
  selectProject.value = Project;
  modalShow.value = true;
}

async function addProject(project: ProjectCreateDto) {
  await apiClient.project
    .create(gid.value, project)
    .catch(httpResponseErrorHandler);
  modalClose();
}
async function modifyProject(sid: number, project: ProjectUpdateDto) {
  await apiClient.project
    .update(gid.value, sid, project)
    .catch(httpResponseErrorHandler);
  modalClose();
}

function modalClose() {
  selectProject.value = null;
  modalShow.value = false;
  isProcessing.value = false;
  router.push({
    name: "group",
    query: { t: Date.now() },
  });
}

// Provider
provide("isProcessing", isProcessing);
provide("selectProject", selectProject);
provide("modalClose", modalClose);
provide("addProject", addProject);
provide("modifyProject", modifyProject);
</script>

<style lang="scss" scoped>
.table {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #ced764;
}

.table thead th button {
  background-color: #0d6efd;
}
.error {
  color: red;
}
</style>
