<template>
  <BaseModal v-if="isShow"><MapForm /></BaseModal>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import BaseModal from "./BaseModal.vue";
import MapForm from "./MapForm.vue";

defineProps({
  isShow: {
    type: Boolean,
    required: true,
  },
});
</script>
