<template>
  <BaseMapForm v-if="meta" :price="values.price">
    <MapTextInput name="index" :displayText="meta.indexName" />
    <MapTextInput
      name="scale"
      displayText="比例尺分母"
      :disabled="meta.fixScale !== ''"
    />
    <MapTextInput name="coord" displayText="坐標系統" v-if="meta.hasCoord" />
    <MapTextInput name="format" displayText="資料格式" v-if="meta.hasFormat" />
    <MapTextInput name="year" displayText="圖資產製日期" v-if="meta.hasYear" />
  </BaseMapForm>
</template>

<script setup lang="ts">
import { MetaMap, QueryCreateDto, QueryMap, QueryUpdateDto } from "@/api/api";
import { injectDefault } from "@/script/common";
import { useForm } from "vee-validate";
import { Ref, inject, provide, ref } from "vue";
import * as yup from "yup";
import BaseMapForm from "./BaseMapForm.vue";
import MapTextInput from "./MapTextInput.vue";

// Inject Secion
const isModify = inject<Ref<boolean>>("isModify", ref(false));
const meta = inject<Ref<MetaMap>>("mapMeta");
const selectQuery = inject<Ref<QueryMap>>("selectQuery");
const addQuery = inject<(query: QueryCreateDto) => void>(
  "addQuery",
  injectDefault
);
const modifyQuery = inject<(qid: number, query: QueryUpdateDto) => void>(
  "modifyQuery",
  injectDefault
);

// Form Section
const schema = yup.object().shape({
  type: yup.string(),
  mid: yup.number(),
  index: yup.string().required(`請輸入${meta?.value.indexName}`),
  scale: yup.string().required("請輸入比例尺分母"),
  coord: yup.string().required("請輸入坐標系統"),
  format: yup.string().when("hasFormat", {
    is: true,
    then: (schema) => schema.required("請輸入資料格式"),
  }),
  year: yup.string().required("請輸入圖資產製日期"),
  price: yup.number().required("請輸入金額"),
  hasFormat: yup.boolean(),
});

const { values, isSubmitting, handleSubmit } = useForm({
  validationSchema: schema,
  initialValues: {
    type: meta?.value.mapName,
    mid: meta?.value.id,
    scale: meta?.value.fixScale,
    price: meta?.value.price,
    hasFormat: meta?.value.hasFormat,
    index: selectQuery?.value?.index || "",
    coord: selectQuery?.value?.coord || "",
    year: selectQuery?.value?.year || "",
    format: selectQuery?.value?.format || meta?.value.fixFormat,
  },
});

const onSubmit = handleSubmit((values) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { hasFormat, ...rest } = values;
  if (isModify.value && selectQuery) {
    const qid = selectQuery.value.qid;
    modifyQuery(qid, rest);
    return;
  }
  addQuery(rest);
});

// Provider
provide("isSubmitting", isSubmitting);
provide("onSubmit", onSubmit);
</script>
