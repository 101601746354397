<template>
  <BaseForm>
    <BaseTextInput name="name" displayText="姓名" placeholder="王小名" />
    <BaseTextInput
      name="email"
      displayText="電子信箱"
      placeholder="example@google.com"
    />
    <BaseTextInput
      name="phone"
      displayText="電話號碼"
      placeholder="0910123456"
    />
    <SchoolSelect />
    <BaseTextInput
      name="subject"
      displayText="科系"
      placeholder="日間部、地理資源學系..."
    />
    <div class="form-group">
      <label for="year">年級</label>
      <input
        v-bind="year"
        name="year"
        type="number"
        rules="minmax: 1, 6"
        min="1"
        max="6"
        class="form-control form-control-lg"
      />
      <div class="error-feedback">{{ errors.year }}</div>
    </div>
  </BaseForm>
</template>

<script setup lang="ts">
import {
  GetSchoolsParamsTypeEnum,
  MetaSchool,
  Student,
  StudentCreateDto,
  StudentUpdateDto,
} from "@/api/api";
import { apiClient } from "@/script/api-client";
import { injectDefault } from "@/script/common";
import { store } from "@/store";
import { useForm } from "vee-validate";
import { Ref, computed, inject, provide, ref, watch } from "vue";
import * as yup from "yup";
import BaseForm from "./BaseForm.vue";
import BaseTextInput from "./BaseTextInput.vue";
import SchoolSelect from "./SchoolSelect.vue";

// Inject Section
const selectStudent = inject<Ref<Student>>("selectStudent");
const isProcessing = inject<Ref<boolean>>("isProcessing", ref(false));
const addStudent = inject<(query: StudentCreateDto) => void>(
  "addStudent",
  injectDefault
);
const modifyStudent = inject<(sid: number, query: StudentUpdateDto) => void>(
  "modifyStudent",
  injectDefault
);
const selectedSchool = computed(() => {
  const select = selectStudent?.value?.school;
  if (select) {
    return select;
  }
  const school = window.sessionStorage.getItem("school");
  if (school) {
    return (JSON.parse(school) as MetaSchool).name;
  }
  return null;
});

// Modify Section
const isModify = computed(() => {
  return selectStudent?.value !== null;
});
// END

const schema = yup.object().shape({
  email: yup.string().required("請輸入信箱").email("信箱格式錯誤"),
  name: yup.string().required("請輸入姓名"),
  phone: yup.string().required("請輸入電話號碼"),
  school: yup.object().required("請選擇學校"),
  subject: yup.string().required("請輸入科系"),
  year: yup.number().min(1).max(6).required("請輸入年級"),
});
const { errors, defineInputBinds, handleSubmit, isSubmitting } = useForm({
  validationSchema: schema,
  initialValues: {
    email: selectStudent?.value?.email || "",
    name: selectStudent?.value?.name || "",
    phone: selectStudent?.value?.phone || "",
    school: null as MetaSchool | null,
    subject: selectStudent?.value?.subject || "",
    year: selectStudent?.value?.year || 1,
  },
});
const year = defineInputBinds("year", { validateOnInput: true });

const onSubmit = handleSubmit(async (values) => {
  isProcessing.value = true;
  const { school, ...rest } = values;
  const payload = {
    ...rest,
    school: school?.name || "",
  };
  window.sessionStorage.setItem("school", JSON.stringify(school));
  if (isModify.value && selectStudent) {
    const sid = selectStudent.value.sid;
    modifyStudent(sid, payload);
    return;
  }
  addStudent(payload);
});

const schools = ref<MetaSchool[]>([]);
const category = computed(() => {
  return store.state.user.group?.category;
});
function schoolNameCompare(front: MetaSchool, back: MetaSchool) {
  return front.name.localeCompare(back.name, "zh-hant");
}
watch(
  category,
  (newCategory) => {
    if (!newCategory) {
      return;
    }
    const _category = newCategory as unknown as GetSchoolsParamsTypeEnum;
    apiClient.getMetaSchools(_category).then((_schools) => {
      _schools.sort(schoolNameCompare);
      schools.value = _schools;
    });
  },
  {
    immediate: true,
  }
);

// Provider
provide("isSubmitting", isSubmitting);
provide("onSubmit", onSubmit);
provide("isModify", isModify);
provide("selectedSchool", selectedSchool);
</script>
